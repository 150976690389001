
import React from 'react';
import { withStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const styles = {
  fw: {
    width: "100%"
  }
};

class NumberCounter extends React.Component {

  increament = () => {
    const { onChange, value } = this.props;
    if (onChange) {
      if (Number.isInteger(value)) {
        onChange(value + 1);
      }
    }
  }

  decrement = () => {
    const { onChange, value } = this.props;
    if (onChange) {
      if (Number.isInteger(value)) {
        if (value > 0) {
          onChange(value - 1);
        }
      }
    }
  }

  render() {
    const { label, value } = this.props;
    return (
      <div style={{ marginTop: "25px" }}>
        <Fab
          size="small"
          color="secondary"
          aria-label="add"
          onClick={this.decrement}
          style={{ backgroundColor: "#4593ea" }}
        >
          <RemoveIcon />
        </Fab>
        <div style={{ display: "inline-block", width: '120px', margin: "0px 20px 0px 20px" }}>
          <span>{label}:</span>
          <span style={{ marginLeft: "10px" }}>{value}</span>
        </div>
        <Fab
          size="small"
          color="secondary"
          aria-label="add"
          onClick={this.increament}
          style={{ backgroundColor: "#4593ea" }}
        >
          <AddIcon />
        </Fab>

      </div>
    );
  }
}

export default withStyles(styles)(NumberCounter);
