import sortBy from 'lodash/sortBy';

class BusStopsHelper {
  static parseDataToReactSelect(reduxBusStops) {
    var results = [];
    for (var name in reduxBusStops) {
      var bs = reduxBusStops[name];
      results.push({ value: name, label: bs.name });
    }
    results = sortBy(results, ['label']);
    return results;
  }
}

export default BusStopsHelper;
