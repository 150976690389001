import React from 'react';
import { Field, reduxForm } from 'redux-form'
import FormTextField from 'components/common/FormTextField';

let LoginForm = props => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="login-form" action="#" method="post">
      <div>
        <Field
          type="text"
          name="login"
          component={FormTextField}
          autoFocus
          required
          label="Login"
        />
      </div>
      <div>
        <Field
          type="password"
          name="password"
          component={FormTextField}
          required
          label="Hasło"
        />
      </div>
      <div className="submit-container">
        <button type="submit" className="login-button">Zaloguj</button>
      </div>
    </form>
  );
}

LoginForm = reduxForm({
  // a unique name for the form
  form: 'login'
})(LoginForm)

export default LoginForm;
