import React, { Component } from 'react';
import { connect } from 'react-redux';
import {store} from "react-notifications-component";
import ReactNotification from "react-notifications-component";
import merge from 'lodash/merge';

// docs:
// https://github.com/teodosii/react-notifications-component

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.notificationDOMRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notification.id !== this.props.notification.id) {
      this.createNotification(this.props.notification.params);
    }
  }

  createNotification = (storeParams) => {
    var defaultParams = {
      title: null,
      message: "Data saved!",
      type: "danger",
      insert: "bottom",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 4000 },
      dismissable: { click: true },
    }
    var params = merge({}, defaultParams, storeParams);
    store.addNotification(params);
  }

  render() {
    return (
      <ReactNotification />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.app.notification
  }
}

export default connect(mapStateToProps)(Notifications);
