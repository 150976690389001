import React from 'react';
import { connect } from 'react-redux';
import { fetchAttendanceList } from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import GoogleMapReact from 'google-map-react';
import PassengersHelper from 'utils/PassengersHelper';
import find from 'lodash/find';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {};

const Marker = (props) => {
  return (<svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="36px"
    height="44px"
    viewBox="0 0 36 44"
    xmlSpace="preserve"
  >
    <path
      fill={props.fill}
      d={`M18,0C8.1,0,0,8.1,0,18c0,9.9,15.7,25.1,15.7,25.1c1.3,1.2,3.3,1.2,4.6,0c0,0,15.7-15.1,15.7-25.1
	    C36,8.1,27.9,0,18,0z`} />
  </svg>);
}

const MarkerComponent = ({ text, fill, color }) => (
  <div className="marker-container" style={{ color: color }}>
    <Marker fill={fill} />
    {text && (
      <div className="marker-container--centered">{text}</div>
    )}
  </div>
);

class AttendanceMap extends React.Component {

  apiIsLoaded = (map, maps) => {
    this.map = map;
    this.maps = maps;
    this.fitBoundsOfMap();
  }

  fitBoundsOfMap = () => {
    const { attedanceList, selectedDrivers } = this.props;
    var passengers = PassengersHelper.parseData(attedanceList);
    const bounds = new this.maps.LatLngBounds();
    var counter = 0;
    passengers.forEach((passenger) => {
      if (selectedDrivers.includes(passenger.driver_name)) {
        counter++;
        bounds.extend(new this.maps.LatLng(
          passenger.lat,
          passenger.lng,
        ));
      }
    });
    if (counter > 0) {
      this.map.fitBounds(bounds);
    }
  }

  onChildClick = (key, childProps) => {
    console.log("click child", childProps)
  }

  getDriverColor = (driver_name) => {
    const { attedanceList } = this.props;
    var drivers = (attedanceList && attedanceList.drivers) ? attedanceList.drivers : [];
    var driver = find(drivers, (o) => o.name === driver_name);
    return driver.color;
  }

  renderMarkers = () => {
    const { attedanceList, selectedDrivers } = this.props;
    var passengers = PassengersHelper.parseData(attedanceList);
    var markers = [];
    for (var i = 0; i < passengers.length; i++) {
      var passenger = passengers[i];
      if (!selectedDrivers.includes(passenger.driver_name)) {
        continue;
      }
      var driverColor = this.getDriverColor(passenger.driver_name);
      var textColor = PassengersHelper.invertColor(driverColor);
      markers.push(
        <MarkerComponent
          key={i}
          lat={passenger.lat}
          lng={passenger.lng}
          text={passenger.order}
          fill={driverColor}
          color={textColor}
        />
      )
    }
    return markers;
  }

  isSelectedDriver = (driverName) => {
    return this.props.selectedDrivers.includes(driverName);
  }

  handleToggle = (driverName) => {
    this.props.toggleDriver(driverName);
    window.setTimeout(this.fitBoundsOfMap, 100);
  }

  renderDriversColors = () => {
    const { attedanceList } = this.props;
    var drivers = (attedanceList && attedanceList.drivers) ? attedanceList.drivers : [];
    var results = [];
    // toggleDriver
    drivers.forEach(driver => {
      results.push(
        <p
          key={driver.name}
          style={{ color: driver.color }}
        >
          <Checkbox
            color="primary"
            style={{ padding: "0", margin: "0" }}
            checked={this.isSelectedDriver(driver.name)}
            onChange={() => this.handleToggle(driver.name)}
          />
          {driver.name}
        </p>
      )
    });
    return results;
  }

  render() {
    var centerCoords = {
      lat: 59.913020,
      lng: 10.752581
    };
    return (
      <div style={{ height: '85vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBM28iY5Jv9N0a-aLbEZEZ9npAg44oxvBk' }}
          defaultCenter={centerCoords}
          defaultZoom={11}
          onChildClick={this.onChildClick}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        >
          {this.renderMarkers()}
        </GoogleMapReact>
        <div className="drivers-container">
          {this.renderDriversColors()}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    fetchingAttendanceList: state.app.fetchingAttendanceList,
    attedanceList: state.app.attedanceList
  };
};

export default withRouter(
  connect(mapStateToProps, { fetchAttendanceList })(withStyles(styles)(AttendanceMap))
);
