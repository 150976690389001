export const HOMEPAGE_PATH = "homepage";
export const LOGIN_PATH = "login";
export const ROUTE_SHOW_PATH = "route_show";
export const RESERVATION_NEW_PATH = "reservation_new";
export const RESERVATION_EDIT_PATH = "reservation_edit";
export const ATTENDANCE_PATH = "attendance_list";
export const SETTLEMENT_PATH = "settlement";
export const SPLIT_RESERVATION_PATH = "split_reservation";
export const EDIT_RESERVATION_PATH = "edit_reservation";

export class PathMatcher {

  static homepagePath = () => {
    return this.path(HOMEPAGE_PATH);
  }

  static routeShowPath = (params) => {
    return this.path(ROUTE_SHOW_PATH, params);
  }

  static reservationCreatePath = (params) => {
    return this.path(RESERVATION_NEW_PATH, params);
  }

  static attendancePath = (params) => {
    return this.path(ATTENDANCE_PATH, params);
  }

  static settlementPath = (params) => {
    return this.path(SETTLEMENT_PATH, params);
  }

  static splitReservationPath = (params) => {
    return this.path(SPLIT_RESERVATION_PATH, params);
  }

  static editReservationPath = (params) => {
    return this.path(EDIT_RESERVATION_PATH, params);
  }

  static routes = () => {
    var routes = {};
    routes[HOMEPAGE_PATH] = "/";
    routes[LOGIN_PATH] = "/login";
    routes[ROUTE_SHOW_PATH] = "/routes/:id";
    routes[RESERVATION_NEW_PATH] = "/routes/:routeId/route-buses/:routeBusId/reservations/create";
    routes[RESERVATION_EDIT_PATH] = "/routes/:routeId/route-buses/:routeBusId/reservations/:id/edit";
    routes[ATTENDANCE_PATH] = "/attendance/:routeId";
    routes[SETTLEMENT_PATH] = "/settlement/:routeId";
    routes[SPLIT_RESERVATION_PATH] = "/split-reservation/:routeId/:reservationId";
    routes[EDIT_RESERVATION_PATH] = "/edit-reservation/:routeId/:reservationId";
    return routes;
  }

  static path = (name, params = {}) => {
    var route = this.routes()[name];
    if (route) {
      var routeWithParams = route;
      for (var paramName in params) {
        var value = params[paramName];
        routeWithParams = routeWithParams.replace(":" + paramName, value);
      }
      return routeWithParams;
    } else {
      throw "Not found route: " + name;
    }
  }

  static routerPath = (name) => {
    var route = this.routes()[name];
    if (route) {
      return route;
    } else {
      throw "Not found route: " + name;
    }
  }

  static isEditReservationPath = (matchObject) => {
    return matchObject.path === this.routerPath(EDIT_RESERVATION_PATH);
  }
}
