import React from 'react';
import { connect } from 'react-redux';
import { setFilterDriverId } from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = {};

class DriverSelect extends React.Component {

  getDriversOptions = () => {
    const { attedanceList } = this.props;
    var results = [];

    if (attedanceList) {
      results.push(
        <MenuItem key="all" value={0}>Wszyscy</MenuItem>
      );
      attedanceList.drivers.forEach(element => {
        results.push(
          <MenuItem
            key={element.id}
            value={element.id}
          >
            {element.name}
          </MenuItem>
        );
      });
    }
    return results;
  }

  changleChangeFilterDriver = (e) => {
    this.props.setFilterDriverId(e.target.value);
  }

  render() {
    return (
      <FormControl style={{ display: "inline-block", marginLeft: "30px" }}>
        <label htmlFor="filter-driver"></label>
        <Select
          value={this.props.attedanceFilterDriverId}
          onChange={this.changleChangeFilterDriver}
          inputProps={{
            name: 'driver',
            id: 'filter-driver',
          }}
        >
          {this.getDriversOptions()}
        </Select>
      </FormControl>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    attedanceList: state.app.attedanceList,
    attedanceFilterDriverId: state.app.attedanceFilterDriverId
  };
};

export default withRouter(
  connect(mapStateToProps, { setFilterDriverId })(withStyles(styles)(DriverSelect))
);
