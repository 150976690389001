import React from 'react';
import LocalStorageHelper from 'utils/LocalStorageHelper';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';
import { withRouter } from "react-router";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// docs:
// https://material-ui.com/components/text-fields/

const styles = {
  footer: {
    backgroundColor: 'white',
    padding: 15,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  title: {
    flexGrow: 1,
  },
  white: {
    color: 'white'
  },
  appbar: {
    backgroundColor: "#4593ea"
  },
  nopadding: {
    padding: 0
  },
  mLeft: {
    marginLeft: "10px"
  }
};

class Layout extends React.Component {

  state = { showLogoutDialog: false };

  logout = (e) => {
    e.preventDefault();
    axios.post('/logout', RequestHelper.signParams({}));
    LocalStorageHelper.removeSalt();
    LocalStorageHelper.removeUid();
    this.setState({ showLogoutDialog: false });
    this.props.history.push("/login");
  }

  refreshAction = () => {
    const { refresh, isLoading } = this.props;
    if (refresh && !isLoading) {
      refresh();
    }
  }

  handleClose = () => {
    this.setState({ showLogoutDialog: false });
  }

  handleOpen = () => {
    this.setState({ showLogoutDialog: true });
  }

  handleClickBack = (e) => {
    e.preventDefault();
    this.props.history.push(this.props.backUrl);
  }

  isStaging = () => {
    return process.env.REACT_APP_PANEL_URL !== "https://panel.pksoslo.com";
  }

  render() {
    const { classes, isLoading, backUrl, hideLoading, sizeh6 } = this.props;
    var fontStyle = { fontSize: "18px" };
    if (sizeh6) {
      fontStyle = { fontSize: sizeh6 };
    }

    const { showLogoutDialog } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar className={classes.appbar} position="relative">
          <Toolbar>
            {backUrl && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                style={{padding: "5px"}}
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleClickBack}
              >
                <ArrowBackIos />
              </IconButton>
            )}

            <Typography className={classes.title} variant="h6" color="inherit" style={fontStyle}>
              {this.props.label}
            </Typography>

            {this.props.elements}

            {!hideLoading && (
              <IconButton
                style={{ marginLeft: "15px" }}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={this.refreshAction}
              >
                {
                  isLoading
                    ?
                    <CircularProgress size={24} className={classes.white} />
                    :
                    <RefreshIcon size={24} />
                }
              </IconButton>
            )}

            <Button
              id="logout-button"
              className={classes.mLeft}
              onClick={this.handleOpen}
              color="inherit"
            >
              Wyloguj
            </Button>

            <span className="app-bar-version">
              {`v${process.env.REACT_APP_VERSION}`}
              {this.isStaging() && (
                <span className="ft12"> [staging]</span>
              )}
            </span>

          </Toolbar>
        </AppBar>
        <main>
          <Container className={classes.nopadding} maxWidth="lg">
            {this.props.children}
          </Container>
        </main>

        <Dialog
          open={showLogoutDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Potwierdź wylogowanie"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz się wylogować aplikacji?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Anuluj
          </Button>
            <Button onClick={this.logout} color="primary" autoFocus>
              Wyloguj
          </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Layout));
