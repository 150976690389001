import React from 'react';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const styles = {
  fw: {
    width: "100%"
  }
};

class GoogleSearchPlaces extends React.Component {

  componentDidMount() {
    var that = this;
    var input = document.getElementById('pac-input');
    var autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.setFields(
      ['address_components', 'geometry', 'name']
    );
    autocomplete.setComponentRestrictions(
      { 'country': ['no'] }
    );
    autocomplete.addListener('place_changed', function () {
      var place = autocomplete.getPlace();
      that.props.selectedPlace(place);
    });
  }

  render() {
    const { classes, error, defaultValue } = this.props;
    var isError = error ? true : false;
    return (
      <FormControl className={classes.fw}>
        <TextField
          defaultValue={defaultValue}
          error={isError}
          type={"text"}
          id="pac-input"
          label={"Adres"}
          margin="normal"
          helperText={error}
        />
      </FormControl>

    );
  }
}

export default withStyles(styles)(GoogleSearchPlaces);
