import React from 'react';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import {
  createErrorNotification, createSuccessNotification
} from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import find from 'lodash/find';
import FlightBar from 'components/common/FlightBar';
import { PathMatcher } from 'PathMatcher';
import ReservationHelper from 'utils/ReservationHelper';
import MergeLiftsWithReservations from 'utils/MergeLiftsWithReservations';
import MainSplitReservation from "./MainSplitReservation";
import SplitForm from "./SplitForm";
import EditReservation from "./EditReservation";
import SplitReservation from "./SplitReservation";
import merge from 'lodash/merge';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';
import CalculatePriceHelper from 'utils/CalculatePriceHelper';

const styles = {};

class EditReservationContainer extends React.Component {

  state = {
    splitParams: {
      adults: 0,
      children: 0,
      rideStatus: "",
      newPrice: null,
      cardPayment: false,
      price: 0,
    },
    error: null,
    isLoading: false
  };

  getRoute = () => {
    const { match, routes } = this.props;
    var routeId = match.params.routeId;
    return find(routes, function (o) { return o.id === parseInt(routeId); });
  }

  getRouteBus = () => {
    var route = this.getRoute();
    return route.route_buses[0];
  }

  appBarElements = () => {
    if (this.props.routes.length === 0) {
      return null;
    }
    var route = this.getRoute();
    var countTotalPeople = ReservationHelper.countTotalPeopleInRoute(route);
    var routeBus = this.getRouteBus();
    return (
      <div className="inline" style={{ position: "relative" }}>
        <FlightBar
          settlementStatus={route.route_buses[0].settlement_status}
          flightDirection={route.flight_direction}
          flights={route.flights}
          isLift={routeBus.is_lift}
        />
        <p style={{ margin: "0", padding: "0", fontSize: "12px" }}>
          Miejsca: {countTotalPeople}/{routeBus.bus.passenger}
        </p>
      </div>
    );
  }

  updateSplitParams = (name, value) => {
    var error = null;
    var splitParams = merge({}, this.state.splitParams);
    splitParams[name] = value;
    error = this.getError(splitParams);
    splitParams.price = this.getNewPrice(splitParams);
    this.setState({ splitParams: splitParams, error: error });
  }

  getNewPrice = (splitParams) => {
    var reservation = this.getReservationData();
    const { prices } = this.props;
    var pc = reservation.address ? reservation.address.post_code : null;
    var obj = {
      adults: splitParams.adults,
      children: splitParams.children,
      students: splitParams.students,
      bus_stop: reservation.bus_stop,
      postcode: pc
    };
    var newPrice = CalculatePriceHelper.calculatePrice(obj, prices);
    return newPrice;
  }

  getError = (splitParams) => {
    var cond1 = splitParams.adults < 0 || splitParams.children < 0;
    var cond2 = splitParams.adults === 0 && splitParams.children === 0;
    var errors = [];
    if (cond1 || cond2) {
      errors.push("Niepoprawna liczba pasażerów.");
    }
    if (Number.isInteger(splitParams.newPrice)) {
      if (splitParams.newPrice <= 0) {
        errors.push("Niepoprawna nowa cena rezerwacji.");
      }
    }

    var originReservation = this.getReservationData();
    var originAdults = originReservation.adult_quantity;
    var originChildren = originReservation.child_quantity;
    if ((splitParams.adults > originAdults || splitParams.children > originChildren) ||
      splitParams.adults === originAdults && splitParams.children === originChildren) {
      errors.push("Za duża liczba nowych pasażerów.");
    }

    if (errors.length === 0) {
      return null;
    } else {
      return errors;
    }
  }

  splitReservation = () => {
    //cancel if is any error
    var error = this.getError(this.state.splitParams);
    if (error !== null) {
      this.setState({ error });
      return;
    }
    this.setState({ isLoading: true });
    var splitParams = this.state.splitParams;
    var params = {
      originReservationId: parseInt(this.props.match.params.reservationId),
      adults: splitParams.adults,
      children: splitParams.children,
      noShow: splitParams.rideStatus === "noshow",
      gratis: splitParams.rideStatus === "gratis",
      cardPayment: splitParams.cardPayment,
      newPrice: splitParams.newPrice,
      price: splitParams.price
    };

    axios.post('/split-reservation', RequestHelper.signParams(params))
      .then(response => {
        this.props.createSuccessNotification({
          message: "Rezerwacja została stworzona."
        });
        this.setState({ isLoading: false });
        var routeId = this.props.match.params.routeId;
        var url = PathMatcher.routeShowPath({ id: routeId });
        this.props.history.push(url);
      })
      .catch(error => {
        console.log("ERRR", error)
        var isUnauthorized = RequestHelper.isUnauthorized(error);
        if (isUnauthorized) {
          this.props.history.push('/login');
        } else if (!RequestHelper.isConnection(error)) {
          alert("No connection!");
        } else {
          this.props.createErrorNotification({
            message: error.response.data.common
          });
        }
        this.setState({ isLoading: false });
      })
  }

  getRoute = () => {
    const { match, routes } = this.props;
    var routeId = match.params.routeId;
    return find(routes, function (o) { return o.id === parseInt(routeId); });
  }

  getRouteBus = () => {
    var route = this.getRoute();
    return route.route_buses[0];
  }

  getReservationData = () => {
    var reservations = new MergeLiftsWithReservations(this.getRoute()).merge();
    var rId = parseInt(this.props.match.params.reservationId);
    return find(reservations, (r) => { return r.id === rId });
  }

  render() {
    const { match } = this.props;
    var routeId = match.params.routeId;
    var reservation = this.getReservationData();
    var isEditComponent = PathMatcher.isEditReservationPath(match);
    if (isEditComponent) {
    }
    console.log("is edit compoment", isEditComponent)
    var backUrl = PathMatcher.routeShowPath({ id: routeId });
    return (
      <Layout
        label="Rezerwacje"
        elements={this.appBarElements()}
        backUrl={backUrl}
      >
        {
          isEditComponent
            ?
            <EditReservation reservation={reservation} />
            :
            <SplitReservation reservation={reservation} />
        }
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    prices: state.app.prices
  };
};

const mapDispatchToProps = {
  createErrorNotification,
  createSuccessNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditReservationContainer))
);
