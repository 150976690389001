import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PhoneIcon from '@material-ui/icons/Phone';
import NavigationIcon from '@material-ui/icons/Navigation';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GpsIcon from '@material-ui/icons/GpsFixed';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import Fab from '@material-ui/core/Fab';
import MoreVert from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import ReservationHelper from 'utils/ReservationHelper';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';
import { PathMatcher } from 'PathMatcher';
import {
  createSuccessNotification, fetchRoutes,
  createErrorNotification
} from 'reducers/appActions';

const styles = {
  red: {
    backgroundColor: "#ff0000",
  },
  yellow: {
    backgroundColor: "#ffc310",
  },
  blue: {
    backgroundColor: "#2195f3",
  },
  green: {
    backgroundColor: "#11ac45",
  },
  orange: {
    backgroundColor: "#ff8100"
  },
  phoneLink: {
    textDecoration: "none",
    color: "inherit"
  }
};

class ReservationItemOptions extends React.Component {

  state = { isOpen: false };

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  openMap = (e) => {
    e.preventDefault();
    const { reservation, busStops } = this.props;
    var rs = new ReservationHelper(reservation, busStops);
    const { lat, lng } = rs.coordinates();
    window.open(`https://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`);
    this.setState({ isOpen: false })
  }

  showMarkerOnMap = () => {
    this.props.showMarkerOnMap(this.props.reservation);
    this.setState({ isOpen: false });
  }

  handlePhoneClick = () => {
    this.setState({ isOpen: false });
  }

  deleteReservation = () => {
    const { reservation, fetchRoutes } = this.props;
    var confirmDelete = window.confirm("Usunąć rezerwację?");
    if (confirmDelete) {
      var params = { id: reservation.id };
      axios.post('/delete-reservation', RequestHelper.signParams(params))
        .then(response => {
          this.props.createSuccessNotification({
            message: "Rezerwacja została usunięta"
          });
          fetchRoutes();
          this.setState({ isOpen: false });
        })
        .catch(error => {
          console.log("ERRR", error)
          var isUnauthorized = RequestHelper.isUnauthorized(error);
          if (isUnauthorized) {
            this.props.history.push('/login');
          } else if (!RequestHelper.isConnection(error)) {
            alert("No connection!");
          } else {
            this.props.createErrorNotification({
              message: "Błąd! rezerwacja nie została usunięta"
            });
          }
        })
    }
  }

  splitReservation = (e) => {
    e.preventDefault();
    const {reservation, history, match} = this.props;
    var url = PathMatcher.splitReservationPath({
      reservationId: reservation.id,
      routeId: match.params.id
    });
    history.push(url);
  }

  render() {
    const { classes, reservation } = this.props;
    const { isOpen } = this.state;
    return (
      <div>
        <Fab
          size="small"
          color="secondary"
          aria-label="add"
          onClick={this.handleOpen}
          style={{ backgroundColor: "#6db2ff" }}
        >
          <MoreVert />
        </Fab>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={isOpen}
        >
          <List>
            <a
              onClick={this.handlePhoneClick}
              className={classes.phoneLink}
              href={`tel:${reservation.customer.phone}`}
            >
              <ListItem button >
                <ListItemAvatar>
                  <Avatar className={classes.blue}>
                    <PhoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Zadzwoń" />
              </ListItem>
            </a>
            <ListItem button onClick={this.openMap}>
              <ListItemAvatar>
                <Avatar className={classes.green}>
                  <NavigationIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Otwórz nawigację" />
            </ListItem>
            <ListItem button onClick={this.showMarkerOnMap}>
              <ListItemAvatar>
                <Avatar className={classes.yellow}>
                  <GpsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Pokaż na mapie" />
            </ListItem>
            <ListItem button onClick={this.splitReservation}>
              <ListItemAvatar>
                <Avatar className={classes.orange}>
                  <CallSplitIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Rozbij" />
            </ListItem>
            {
              reservation.created_by_driver
                ?
                <ListItem button onClick={this.deleteReservation}>
                  <ListItemAvatar>
                    <Avatar className={classes.red}>
                      <DeleteForeverIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Usuń" />
                </ListItem>
                :
                null
            }
          </List>
        </Dialog>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    busStops: state.app.busStops,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { createSuccessNotification, createErrorNotification, fetchRoutes }
  )(withStyles(styles)(ReservationItemOptions))
);
