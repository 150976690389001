import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/styles';

// docs:
// https://material-ui.com/components/text-fields/

const styles = {
  fw: {
    width: "100%"
  }
};

class FormTextField extends React.Component {
  render() {
    const { input, type, label, classes, meta: { touched, invalid, error }, ...custom } = this.props;
    const isError = error ? true : false;
    return (
      <FormControl className={classes.fw} error={isError}>
        <TextField
          type={type}
          helperText={touched && error}
          error={touched && invalid}
          id="standard-error"
          label={label}
          margin="normal"
          {...input}
          {...custom}
        />

      </FormControl>
    );
  }
}

export default withStyles(styles)(FormTextField);;
