
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import { withStyles } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

// docs:
// https://material-ui.com/components/text-fields/

// const useStyles = makeStyles(theme => ({
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
//   formControl: {
//     margin: theme.spacing(1),
//   },
// }));

// const classes = useStyles();

const styles = {
  fw: {
    width: "100%"
  }
};

class FormSelectField extends React.Component {

  render() {
    const { input, type, label, classes, suggestions, meta: { touched, invalid, error }, ...custom } = this.props;
    const isError = error ? true : false;
    if (isError) {
      var selectStyles = {
        ...styles,
        control: (base, state) => ({
          ...base,
          '&:hover': { borderColor: 'red' }, // border style on hover
          border: '1px solid red', // default border color
          boxShadow: 'none', // no box-shadow
        }),
      };
    } else {
      var selectStyles = {
        ...styles,
        control: (base, state) => ({
          ...base,
          '&:hover': { borderColor: 'gray' }, // border style on hover
          border: '1px solid #929292', // default border color
          boxShadow: 'none', // no box-shadow
        }),
      };
    }
    return (
      <FormControl error={isError} className={classes.fw} error={isError}>
        <Select
          styles={selectStyles}
          inputId="react-select-single"
          isSearchable={false}
          TextFieldProps={{
            label: 'Przystanki',
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
            },
          }}
          placeholder="Wyszukaj przystanek"
          options={suggestions}
          value={input.value}
          onChange={input.onChange}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    );
  }
}

export default withStyles(styles)(FormSelectField);
