import React from 'react';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import { fetchRoutes, fetchBusStops } from 'reducers/appActions';
import RouteItem from './RouteItem';
import LocalStorageHelper from 'utils/LocalStorageHelper';
import { withRouter } from "react-router";
import includes from 'lodash/includes';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

const styles = {
  contained: {
    marginLeft: "15px",
    backgroundColor: "#4593ea",
    color: "white",
    "&:hover": {
      backgroundColor: "#1968bf",
    }
  }
};

class RoutesPage extends React.Component {

  state = { tabIndex: 0 };

  componentDidMount() {
    if (LocalStorageHelper.isTokenData()) {
      this.subscribeUser();
      this.props.fetchRoutes();
      this.props.fetchBusStops();
    } else {
      this.props.history.push("/login");
    }
  }

  urlBase64ToUint8Array = () => {
    var base64String = 'BNDNFYxZptf7kpKx4cypGXI70VHKj7HvWEcp5S-FW0_mj-3y8Ke3h3tjuNtwI05YMA_EFh1Frfd6DmRKbdXV48c';

    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  subscribeUser = () => {
    var that = this;
    if ('serviceWorker' in navigator) {
      console.log("subscribeUser");
      navigator.serviceWorker.ready.then(function (reg) {
        reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: that.urlBase64ToUint8Array(),
        }).then(function (sub) {
          console.log('Endpoint URL: ', sub);
          var p = { endpoint: sub.endpoint };
          axios.post('/save-endpoint', RequestHelper.signParams(p));

        }).catch(function (e) {
          if (Notification.permission === 'denied') {
            console.warn('Permission for notifications was denied');
          } else {
            console.error('Unable to subscribe to push');
            console.error(e);
          }
        });
      })
    }
  }

  routesItemsData = (completedFilter) => {
    const { routes, completedRoutes } = this.props;
    var items = [];
    for (var i = 0; i < routes.length; i++) {
      var route = routes[i];
      var isCompleted = includes(completedRoutes, route.id);
      if (completedFilter) {
        if (isCompleted) {
          items.push(<RouteItem data={route} key={route.id} />);
        }
      } else {
        if (!isCompleted) {
          items.push(<RouteItem data={route} key={route.id} />);
        }
      }
    }
    return { items: items, size: items.length };
  }

  handleChangeTab = (event, value) => {
    this.setState({ tabIndex: value });
  }

  refreshAction = () => {
    this.props.fetchRoutes();
    this.props.fetchBusStops();
  }

  goToDriversList = (e) => {
    e.preventDefault();
    this.props.history.push('/drivers-list');
  }

  appBarElements = () => {
    return (
      <div className="inline">
        <Button
          className={this.props.classes.contained}
          size="small"
          variant="contained"
          type="submit"
          onClick={this.goToDriversList}
        >
          Kontakty
      </Button>
      </div>
    );
  }

  render() {
    const { fetchingRoutes, fetchingBusStops } = this.props;
    const { tabIndex } = this.state;
    const { size: activeRoutesSize, items: activeRoutes }
      = this.routesItemsData(false);
    const { size: comletedRoutesSize, items: completedRoutes }
      = this.routesItemsData(true);

    return (
      <Layout
        label="Lista tras"
        isLoading={fetchingRoutes || fetchingBusStops}
        refresh={this.refreshAction}
        elements={this.appBarElements()}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs"
          >
            <Tab
              label={`W toku (${activeRoutesSize})`}
              id="full-width-tab-0"
              aria-controls='full-width-tabpanel-0'
            />
            <Tab
              label={`Zakończone (${comletedRoutesSize})`}
              id="full-width-tab-1"
              aria-controls='full-width-tabpanel-1'
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tabIndex} index={0}>
          <div>
            {
              activeRoutesSize === 0
                ?
                <Typography align="center">
                  Brak tras
                </Typography>
                :
                <div>
                  {activeRoutes}
                </div>
            }
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div>
            {
              comletedRoutesSize === 0
                ?
                <Typography align="center">
                  Brak tras
                </Typography>
                :
                <div>
                  {completedRoutes}
                </div>
            }
          </div>
        </TabPanel>
      </Layout>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    completedRoutes: state.app.completedRoutes,
    fetchingRoutes: state.app.fetchingRoutes,
    fetchingBusStops: state.app.fetchingBusStops
  };
};

export default withRouter(
  connect(mapStateToProps, { fetchRoutes, fetchBusStops })(withStyles(styles)(RoutesPage))
);

