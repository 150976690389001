
class MergeLiftsWithReservations {
  constructor(route) {
    this.route = route;
    return this;
  }

  merge() {
    var results = [];
    var reservations = this.route.route_buses[0].reservations;
    for (var j = 0; j < reservations.length; j++) {
      reservations[j].is_main_route_bus = true;
      results.push(reservations[j]);
    }
    var lifts = this.route.route_buses[0].lifts;
    for (var key in lifts) {
      var lift = lifts[key];
      var liftReservations = lift.reservations;
      for (var i = 0; i < liftReservations.length; i++) {
        liftReservations[i].is_main_route_bus = false;
        results.push(liftReservations[i]);
      }
    }
    return results;
  }

}

export default MergeLiftsWithReservations;
