class SettlementRouteHelper {
  constructor(route) {
    this.route = route;
    this.reservations = route.route_buses[0].reservations;
    this.adultsOnBoard = 0;
    this.childrenOnBoard = 0;
    this.adultsNoShow = 0;
    this.childrenNoShow = 0;
    this.adultsGratis = 0;
    this.childrenGratis = 0;
    this.paidOnline = 0;
    this.totalCash = 0;
    this.paidUsingCard = 0;
    this.splittedCardAmount = 0;
    this.passengersOnBoard();
    this.passengersNoShow();
    this.passengersGratis();
    this.calculateCash();
  }

  calculateCash = () => {
    this.reservations.forEach(r => {
      if (r.is_paid) {
        this.paidOnline += r.price;
      } else if (r.no_show !== true && r.gratis !== true) {
        if (r.card_payment_in_bus) {
          this.paidUsingCard += r.price;
        } else {
          this.totalCash += r.price;
        }
        if (r.splitted_payment_amount) {
          this.splittedCardAmount += r.splitted_payment_amount;
        }
      }
    });
    this.totalCash = this.totalCash - this.splittedCardAmount;
    this.paidUsingCard = this.paidUsingCard + this.splittedCardAmount;
  }

  totalCashInBus = (costs) => {
    var parsedCosts = parseInt(costs);
    if (!Number.isInteger(parsedCosts)) {
      parsedCosts = 0;
    }
    return this.totalCash - parsedCosts;
  }

  passengersOnBoard = () => {
    this.reservations.forEach(r => {
      if (r.status !== 7) {
        this.adultsOnBoard += r.adult_quantity;
        this.childrenOnBoard += r.child_quantity;
      }
    });
  }

  passengersNoShow = () => {
    this.reservations.forEach(r => {
      if (r.status === 7) {
        this.adultsNoShow += r.adult_quantity;
        this.childrenNoShow += r.child_quantity;
      }
    });
  }

  passengersGratis = () => {
    this.reservations.forEach(r => {
      if (r.gratis) {
        this.adultsGratis += r.adult_quantity;
        this.childrenGratis += r.child_quantity;
      }
    });
  }

}

export default SettlementRouteHelper;
