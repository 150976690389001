export const FETCH_ROUTES = 'FETCH_ROUTES';
export const FETCH_ROUTES_SUCCESS = 'FETCH_ROUTES_SUCCESS';
export const FETCH_ROUTES_FAIL = 'FETCH_ROUTES_FAIL';
export const TOGGLE_ROUTE = 'TOGGLE_ROUTE';
export const CHECK_ROUTE = 'CHECK_ROUTE';
export const CHECK_ROUTE_SUCCESS = 'CHECK_ROUTE_SUCCESS';
export const CHECK_ROUTE_FAIL = 'CHECK_ROUTE_FAIL';
export const START_ROUTE = 'START_ROUTE';
export const START_ROUTE_SUCCESS = 'START_ROUTE_SUCCESS';
export const START_ROUTE_FAIL = 'START_ROUTE_FAIL';
export const END_ROUTE = 'END_ROUTE';
export const END_ROUTE_SUCCESS = 'END_ROUTE_SUCCESS';
export const END_ROUTE_FAIL = 'END_ROUTE_FAIL';
export const FETCH_PRICES = 'FETCH_PRICES';
export const FETCH_PRICES_SUCCESS = 'FETCH_PRICES_SUCCESS';
export const FETCH_BUS_STOPS = 'FETCH_BUS_STOPS';
export const FETCH_BUS_STOPS_SUCCESS = 'FETCH_BUS_STOPS_SUCCESS';
export const FETCH_BUS_STOPS_FAIL = 'FETCH_BUS_STOPS_FAIL';
export const TOGGLE_RESERVATION = 'TOGGLE_RESERVATION';
export const SET_ROUTES_CHANGES = 'SET_ROUTES_CHANGES';
export const REMOVE_RESERVATION_FROM_CHANGES = 'REMOVE_RESERVATION_FROM_CHANGES';
export const REMOVE_ROUTE_FROM_CHANGES = 'REMOVE_ROUTE_FROM_CHANGES';
export const FETCH_ATTENDANCE_LIST = 'FETCH_ATTENDANCE_LIST';
export const FETCH_ATTENDANCE_LIST_SUCCESS = 'FETCH_ATTENDANCE_LIST_SUCCESS';
export const FETCH_ATTENDANCE_LIST_FAIL = 'FETCH_ATTENDANCE_LIST_FAIL';
export const NO_SHOW_RESERVATION_REQUEST = 'NO_SHOW_RESERVATION_REQUEST';
export const NO_SHOW_RESERVATION_REQUEST_SUCCESS = 'NO_SHOW_RESERVATION_REQUEST_SUCCESS';
export const NO_SHOW_RESERVATION_REQUEST_FAIL = 'NO_SHOW_RESERVATION_REQUEST_FAIL';
export const SET_FILTER_DRIVER_ID = 'SET_FILTER_DRIVER_ID';
export const CLEAR_ATTENDANCE_LIST = 'CLEAR_ATTENDANCE_LIST';
export const FETCH_DRIVERS_LIST = 'FETCH_DRIVERS_LIST';
export const FETCH_DRIVERS_LIST_SUCCESS = 'FETCH_DRIVERS_LIST_SUCCESS';
export const SET_PLACE_IN_RESERVATION_FORM = 'SET_PLACE_IN_RESERVATION_FORM';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const GRATIS_RESERVATION_REQUEST = 'GRATIS_RESERVATION_REQUEST';
export const GRATIS_RESERVATION_REQUEST_SUCCESS = 'GRATIS_RESERVATION_REQUEST_SUCCESS';
export const GRATIS_RESERVATION_REQUEST_FAIL = 'GRATIS_RESERVATION_REQUEST_FAIL';
export const CARD_PAYMENT_RESERVATION_REQUEST = 'CARD_PAYMENT_RESERVATION_REQUEST';
export const CARD_PAYMENT_RESERVATION_REQUEST_SUCCESS = 'CARD_PAYMENT_RESERVATION_REQUEST_SUCCESS';
export const CARD_PAYMENT_RESERVATION_REQUEST_FAIL = 'CARD_PAYMENT_RESERVATION_REQUEST_FAIL';
export const CHANGE_PRICE_RESERVATION_REQUEST = 'CHANGE_PRICE_RESERVATION_REQUEST';
export const CHANGE_PRICE_RESERVATION_REQUEST_SUCCESS = 'CHANGE_PRICE_RESERVATION_REQUEST_SUCCESS';
export const CHANGE_PRICE_RESERVATION_REQUEST_FAIL = 'CHANGE_PRICE_RESERVATION_REQUEST_FAIL';
export const REMOVE_NOT_SHOW_AND_GRATIS_REQUEST = 'REMOVE_NOT_SHOW_AND_GRATIS_REQUEST';
export const REMOVE_NOT_SHOW_AND_GRATIS_REQUEST_SUCCESS = 'REMOVE_NOT_SHOW_AND_GRATIS_REQUEST_SUCCESS';
export const REMOVE_NOT_SHOW_AND_GRATIS_REQUEST_SUCCESS_FAIL = 'REMOVE_NOT_SHOW_AND_GRATIS_REQUEST_SUCCESS_FAIL';
export const SET_TRACKING_LOCALIZATION = 'SET_TRACKING_LOCALIZATION';
