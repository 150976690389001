import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import arrivalIcon from 'components/assets/icons/route_arrival_enabled.png';
import departureIcon from 'components/assets/icons/route_departure_enabled.png';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { toggleRoute } from 'reducers/appActions';
import includes from 'lodash/includes';
import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import sortBy from 'lodash/sortBy';
import { PathMatcher } from 'PathMatcher';
import SettlementStatus from 'components/common/SettlementStatus';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

const styles = {
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  notificationButton: {
    fontSize: "10px",
    marginBottom: "5px"
  }
};

class RouteItem extends React.Component {

  getFlightName(flights) {
    if (!flights) {
      return "";
    }
    var result = [];
    var direction = "";
    var airport = flights[0].airport;
    for (var i = 0; i < flights.length; i++) {
      var flight = flights[i];
      result.push(flight.city.name)
      direction = flight.direction;
    }
    if (direction === "arrive") {
      return result.join(", ") + " - " + airport.name;
    } else {
      return airport.name + " - " + result.join(", ");
    }
  }

  getBusPlate(routeBuses) {
    if (!routeBuses) {
      return "";
    }
    var result = [];
    for (var i = 0; i < routeBuses.length; i++) {
      var routeBus = routeBuses[i];
      result.push(routeBus.bus.name);
    }
    return result.join(", ");
  }

  getFlightDate(flights) {
    if (!flights) {
      return "";
    }
    var date = flights[0].date;
    return moment(date).format("DD.MM.YYYY");
  }

  handleClickCheckbox = (e) => {
    this.props.toggleRoute(this.props.data.id);
  }

  isCompletedRoute = () => {
    const { data, completedRoutes } = this.props;
    return includes(completedRoutes, data.id);
  }

  openRoute = (e) => {
    var url = PathMatcher.routeShowPath({ id: this.props.data.id });
    this.props.history.push(url);
  }

  isChangedRoute = () => {
    const { routesChanges } = this.props;
    const { id: routeId } = this.props.data;
    var route = routesChanges[routeId];
    var changed = false;
    if (route) {
      if (route.reservations) {
        changed = Object.keys(route.reservations).length !== 0;
      }
      if (route.global && changed === false) {
        changed = true;
      }
    }
    return changed;
  }

  getFlightsTimeLabel = (flights) => {
    var sortedFlights = sortBy(
      flights,
      function (o) { return new moment(o.date); }
    );
    var results = [];
    sortedFlights.forEach((f) => {
      var time = moment(f.date).tz('Europe/Warsaw').format("HH:mm");
      results.push(time);
    })
    return results.join(", ");
  }

  render() {
    const { flight_direction, flights, route_buses } = this.props.data;
    const { classes } = this.props;
    var isUpdate = this.isChangedRoute();

    return (
      <Grid
        className={classes.rowItem}
        container
        spacing={0}
        alignContent="center"
      >
        <Grid item xs={1} className="ripple">
          <Checkbox
            style={{ padding: "5px" }}
            checked={this.isCompletedRoute()}
            onChange={this.handleClickCheckbox}
          />
        </Grid>
        <Grid item xs={1} className="ripple">
          {
            route_buses[0].is_lift
              ?
              <DirectionsBusIcon className="routeIcon liftIcon liftRouteIcon" />
              :
              <Fragment>
                {flight_direction === "arrive" && (
                  <img alt='arrive' className="routeIcon" src={arrivalIcon} />
                )}
                {flight_direction === "flight" && (
                  <img alt='departure' className="routeIcon" src={departureIcon} />
                )}
              </Fragment>
          }
          <SettlementStatus status={route_buses[0].settlement_status} />
        </Grid>
        <Grid onClick={this.openRoute} item xs={6} className="ripple">
          {this.getFlightName(flights)}
          <br />
          <p style={{ margin: "0 0 3px 0", fontSize: "15px" }}>
            {this.getFlightsTimeLabel(flights)}
          </p>
          {isUpdate && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className={classes.notificationButton}
            >
              Aktualizacja
          </Button>
          )}
        </Grid>
        <Grid onClick={this.openRoute} item xs={2} className="ripple">
          {this.getBusPlate(route_buses)}
        </Grid>
        <Grid onClick={this.openRoute} item xs={2} className="ripple">
          {this.getFlightDate(flights)}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    completedRoutes: state.app.completedRoutes,
    routesChanges: state.app.routesChanges
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleRoute })(withStyles(styles)(RouteItem))
);
