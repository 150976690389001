import React from 'react';
import { withStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

const SETTLEMENT_NOT_SEND = 0;
const SETTLEMENT_ACCEPTED = 1;
const SETTLEMENT_REJECTED = 2;
const SETTLEMENT_PENDING = 3;
const styles = {};

class SettlementStatus extends React.Component {

  render() {
    const { status } = this.props;
    if (status === null || status === SETTLEMENT_NOT_SEND) {
      return null;
      // return (
      //   <Tooltip enterTouchDelay={0} title={"Rozliczenie nie wysłane"}>
      //     <HelpIcon />
      //   </Tooltip>
      // );
    }

    if (status === SETTLEMENT_ACCEPTED) {
      return (
        <Tooltip enterTouchDelay={0} title={"Rozliczenie zaakceptowane"}>
          <CheckCircleIcon style={{color: '#5dd25d'}} />
        </Tooltip>
      );
    }

    if (status === SETTLEMENT_PENDING) {
      return (
        <Tooltip enterTouchDelay={0} title={"Rozliczenie wysłane"}>
          <HourglassFullIcon style={{color: 'orange'}} />
        </Tooltip>
      );
    }

    if (status === SETTLEMENT_REJECTED) {
      return (
        <Tooltip enterTouchDelay={0} title={"Rozliczenie odrzucone"}>
          <CancelIcon style={{color: 'red'}} />
        </Tooltip>
      );
    }
    return null;
  }
}

export default withStyles(styles)(SettlementStatus);
