import find from 'lodash/find';
import MergeLiftsWithReservations from 'utils/MergeLiftsWithReservations';

class ReservationChangesHelper {

  constructor(routes, newRoutes) {
    this.routes = routes;
    this.newRoutes = newRoutes;
  }

  findReservationsChanged() {
    var routesChanges = {};
    for (var i = 0; i < this.routes.length; i++) {
      var route = this.routes[i];
      //if route in app is deleted skip compares
      if (this.isDeletedRoute(route)) {
        continue;
      }
      routesChanges[route.id] = {};

      if (this.busChanged(route)) {
        routesChanges[route.id]["bus"] = true;
      }

      var reservations = new MergeLiftsWithReservations(route).merge();
      for (var j = 0; j < reservations.length; j++) {
        var reservation = reservations[j];

        var reservationChanges = {};
        //if reservation in app is deleted skip compares
        if (this.isDeletedReservations(route, reservation.id)) {
          routesChanges[route.id]["global"] = true;
          if (routesChanges[route.id]["reservations"]) {
            if (routesChanges[route.id]["reservations"][reservation.id]) {
              delete routesChanges[route.id]["reservations"][reservation.id];
            }
          }
          continue;
        }

        var newReservation = this.findNewReservation(route, reservation.id);
        // *** keep checking reservation fields *** //
        if (this.adultQuantityChanged(reservation, newReservation)) {
          reservationChanges["adult_quantity"] = true;
        }
        if (this.childQuantityChanged(reservation, newReservation)) {
          reservationChanges["child_quantity"] = true;
        }
        if (this.busStopChanged(reservation, newReservation)) {
          reservationChanges["bus_stop"] = true;
        }
        if (this.placeChanged(reservation, newReservation)) {
          reservationChanges["place"] = true;
        }
        if (this.busArrivalChanged(reservation, newReservation)) {
          reservationChanges["bus_arrival"] = true;
        }
        if (this.flightChanged(reservation, newReservation)) {
          reservationChanges["flight"] = true;
        }
        if (this.priceChanged(reservation, newReservation)) {
          reservationChanges["price"] = true;
        }
        if (this.seatsChanged(reservation, newReservation)) {
          reservationChanges["seats"] = true;
        }
        if (this.isReservationChanged(reservation, newReservation)) {
          reservationChanges["global"] = true;
        }

        // check are changes in reservation //
        if (!this.isEmpty(reservationChanges)) {
          routesChanges[route.id]["reservations"] = {}
          routesChanges[route.id]["reservations"][reservation.id] = reservationChanges;
        }
      }
    }

    //check for new reservations:
    for (var i = 0; i < this.newRoutes.length; i++) {
      var newRoute = this.newRoutes[i];
      if (!routesChanges[newRoute.id]) {
        routesChanges[newRoute.id] = {};
        routesChanges[newRoute.id]["global"] = true;
      }
      var reservations = new MergeLiftsWithReservations(newRoute).merge();
      for (var j = 0; j < reservations.length; j++) {
        var reservation = reservations[j];
        if (!this.isAlreadyInOldRoutes(newRoute.id, reservation.id)) {
          if (!routesChanges[newRoute.id]["reservations"]) {
            routesChanges[newRoute.id]["reservations"] = {};
            routesChanges[newRoute.id]["reservations"][reservation.id] = {};
          }
          routesChanges[newRoute.id]["reservations"][reservation.id] = { global: true };
        }
      }
    }
    return routesChanges;
  }

  getChangesInReservation() {

  }

  isDeletedRoute(route) {
    return !this.findNewRoute(route);
  }

  isDeletedReservations(route, reservationId) {
    return !this.findNewReservation(route, reservationId);
  }

  findNewRoute(route) {
    return find(this.newRoutes, function (o) { return o.id === route.id; });
  }

  isAlreadyInOldRoutes(routeId, reservationId) {
    var route = find(this.routes, function (o) { return o.id === routeId; });
    if (!route) {
      return false;
    }
    var reservations = new MergeLiftsWithReservations(route).merge();
    return find(
      reservations,
      function (o) { return o.id === reservationId; }
    );
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  findNewReservation(route, reservationId) {
    var newRoute = this.findNewRoute(route);
    var reservations = new MergeLiftsWithReservations(route).merge();
    return find(
      reservations,
      function (o) { return o.id === reservationId; }
    );
  }

  busChanged(route) {
    var newRoute = this.findNewRoute(route);
    return route.route_buses[0].bus.name !== newRoute.route_buses[0].bus.name;
  }

  adultQuantityChanged(reservation, newReservation) {
    return reservation.adult_quantity !== newReservation.adult_quantity;
  }

  childQuantityChanged(reservation, newReservation) {
    return reservation.child_quantity !== newReservation.child_quantity;
  }

  busStopChanged(reservation, newReservation) {
    return reservation.bus_stop !== newReservation.bus_stop;
  }

  placeChanged(reservation, newReservation) {
    return reservation.place !== newReservation.place;
  }

  busArrivalChanged(reservation, newReservation) {
    return reservation.bus_arrival !== newReservation.bus_arrival;
  }

  flightChanged(reservation, newReservation) {
    return reservation.flight.date !== newReservation.flight.date;
  }

  priceChanged(reservation, newReservation) {
    return reservation.price !== newReservation.price;
  }

  seatsChanged(reservation, newReservation) {
    return JSON.stringify(reservation.reservation_seats) !==
      JSON.stringify(newReservation.reservation_seats);
  }

  isReservationChanged(reservation, newReservation) {
    var x = JSON.stringify(reservation) !== JSON.stringify(newReservation);
    // if(x){
    //   console.log(JSON.stringify(reservation))
    //   console.log(JSON.stringify(newReservation))
    // }
    return x;
  }

  findReservationById(reservationId) {
    for (var i = 0; i < this.routes.length; i++) {
      var route = this.routes[i];
      var reservations = new MergeLiftsWithReservations(route).merge();
      for (var j = 0; j < reservations.length; j++) {
        var reservation = reservations[j];
        if (reservation.id === reservationId) {
          return reservation;
        }
      }
    }
    return null;
  }

  changeReservationToNoShow = (reservationId) => {
    var reservation = this.findReservationById(reservationId);
    if (reservation) {
      // reservation.status = value ? 7 : 5;
      reservation.status = 7;
      reservation.no_show = true;
    }
    return this.routes;
  }

  changeReservationToGratis = (reservationId) => {
    var reservation = this.findReservationById(reservationId);
    if (reservation) {
      reservation.gratis = true;
      reservation.status = 5;
      reservation.no_show = false;
    }
    return this.routes;
  }

  removeGratisAndNoShow = (reservationId) => {
    var reservation = this.findReservationById(reservationId);
    if (reservation) {
      reservation.gratis = false;
      reservation.status = 5;
      reservation.no_show = false;
    }
    return this.routes;
  }

  changeReservationCardPayment = (reservationId, value) => {
    var reservation = this.findReservationById(reservationId);
    if (reservation) {
      reservation.card_payment_in_bus = value;
    }
    return this.routes;
  }

  changeReservationPrice = (reservationId, value) => {
    var reservation = this.findReservationById(reservationId);
    if (reservation) {
      reservation.price = value;
    }
    return this.routes;
  }

}

export default ReservationChangesHelper;
