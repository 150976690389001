import * as AC from './actionTypes';
import merge from 'lodash/merge';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import * as C from 'utils/constants';
import LocalStorageHelper from 'utils/LocalStorageHelper';
import ReservationChangesHelper from 'utils/ReservationChangesHelper';

let initialState = {
  notification: { id: null, params: null },
  attedanceList: null,
  driversList: [],
  fetchingDriverList: false,
  fetchingRoutes: false,
  fetchingBusStops: false,
  fetchingAttendanceList: false,
  busStops: LocalStorageHelper.getBusStops([]),
  routes: LocalStorageHelper.getRoutes([]),
  completedRoutes: LocalStorageHelper.getCompletedRoutes([]),
  completedReservations: LocalStorageHelper.getCompletedReservations([]),
  routeData: null,
  routeDataLoading: false,
  startedRouteId: null,
  routesChanges: LocalStorageHelper.getRoutesChanges({}),
  sendingRequestReservation: false,
  attedanceFilterDriverId: 0,
  reservationSelectedPlace: null,
  prices: LocalStorageHelper.getPrices(null),
  fetchingPrices: false,
  sendingSettlement: false,
  driverLocation: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AC.FETCH_ROUTES:
      return merge({}, state, { fetchingRoutes: true });

    case AC.FETCH_ROUTES_SUCCESS:
      LocalStorageHelper.saveRoutes(action.payload.routes);
      var newState = merge({}, state, { fetchingRoutes: false });
      newState.routes = action.payload.routes;
      return newState;

    case AC.TOGGLE_ROUTE:
      var newState = merge({}, state);
      var id = action.payload;
      var isCompleted = includes(newState.completedRoutes, id);
      if (isCompleted) {
        remove(newState.completedRoutes, function (n) {
          return n === id;
        });
      } else {
        newState.completedRoutes.push(id);
      }
      LocalStorageHelper.saveCompletedRoutes(newState.completedRoutes);
      return newState;

    case AC.TOGGLE_RESERVATION:
      var newState = merge({}, state);
      var id = action.payload;
      var isCompleted = includes(newState.completedReservations, id);
      if (isCompleted) {
        remove(newState.completedReservations, function (n) {
          return n === id;
        });
      } else {
        newState.completedReservations.push(id);
      }
      LocalStorageHelper.saveCompletedReservations(newState.completedReservations);
      return newState;

    case AC.CHECK_ROUTE:
      return merge({}, state, { routeDataLoading: true, routeData: null });

    case AC.CLEAR_ATTENDANCE_LIST:
      var newState = merge({}, state);
      newState.attedanceList = null;
      return newState;

    case AC.CHECK_ROUTE_SUCCESS:
      return merge(
        {},
        state,
        {
          routeDataLoading: false,
          routeData: action.payload
        }
      );

    case AC.START_ROUTE:
      return merge({}, state, { routeDataLoading: true });

    case AC.START_ROUTE_SUCCESS:
      return merge({}, state, {
        routeDataLoading: false,
        startedRouteId: action.payload,
        routeData: { status: C.STARTED_ROUTE }
      });

    case AC.START_ROUTE_FAIL:
      return merge({}, state, { routeDataLoading: false });

    case AC.FETCH_DRIVERS_LIST:
      return merge({}, state, { fetchingDriverList: true });

    case AC.END_ROUTE:
      return merge({}, state, { routeDataLoading: true });

    case AC.END_ROUTE_SUCCESS:
      return merge({}, state, {
        routeDataLoading: false,
        startedRouteId: null,
        routeData: { status: C.ENDED_ROUTE }
      });

    case AC.FETCH_DRIVERS_LIST_SUCCESS:
      var newState = merge({}, state);
      newState.driversList = action.payload;
      newState.fetchingDriverList = false;
      return newState;

    case AC.END_ROUTE_FAIL:
      return merge({}, state, { routeDataLoading: false });

    case AC.FETCH_BUS_STOPS:
      return merge({}, state, { fetchingBusStops: true });

    case AC.FETCH_BUS_STOPS_SUCCESS:
      LocalStorageHelper.saveBusStops(action.payload);
      return merge({}, state, { fetchingBusStops: false, busStops: action.payload });

    case AC.FETCH_PRICES:
      return merge({}, state, { fetchingPrices: true });

    case AC.FETCH_PRICES_SUCCESS:
      LocalStorageHelper.savePrices(action.payload);
      return merge({}, state, { fetchingPrices: false, prices: action.payload });

    case AC.SET_ROUTES_CHANGES:
      var mergedChanges = merge({}, state.routesChanges, action.payload);
      LocalStorageHelper.saveRoutesChanges(mergedChanges);
      return merge({}, state, { routesChanges: mergedChanges });

    case AC.FETCH_ATTENDANCE_LIST:
      return merge({}, state, { fetchingAttendanceList: true });

    case AC.FETCH_ATTENDANCE_LIST_SUCCESS:
      return merge({}, state, { fetchingAttendanceList: false, attedanceList: action.payload });

    case AC.NO_SHOW_RESERVATION_REQUEST:
    case AC.GRATIS_RESERVATION_REQUEST:
    case AC.CARD_PAYMENT_RESERVATION_REQUEST:
    case AC.CHANGE_PRICE_RESERVATION_REQUEST:
    case AC.REMOVE_NOT_SHOW_AND_GRATIS_REQUEST:
      return merge({}, state, { sendingRequestReservation: true });

    case AC.CHANGE_PRICE_RESERVATION_REQUEST_FAIL:
      return merge({}, state, { sendingRequestReservation: false });

    case AC.NO_SHOW_RESERVATION_REQUEST_SUCCESS:
      var newState = merge({}, state, { sendingRequestReservation: false });
      var helper = new ReservationChangesHelper(newState.routes, null);
      newState.routes = helper.changeReservationToNoShow(
        action.payload.reservationId,
      );
      return newState;

    case AC.GRATIS_RESERVATION_REQUEST_SUCCESS:
      var newState = merge({}, state, { sendingRequestReservation: false });
      var helper = new ReservationChangesHelper(newState.routes, null);
      newState.routes = helper.changeReservationToGratis(
        action.payload.reservationId,
      );
      return newState;

    case AC.CARD_PAYMENT_RESERVATION_REQUEST_SUCCESS:
      var newState = merge({}, state, { sendingRequestReservation: false });
      var helper = new ReservationChangesHelper(newState.routes, null);
      newState.routes = helper.changeReservationCardPayment(
        action.payload.reservationId,
        action.payload.value
      );
      return newState;

    case AC.REMOVE_NOT_SHOW_AND_GRATIS_REQUEST_SUCCESS:
      var newState = merge({}, state, { sendingRequestReservation: false });
      var helper = new ReservationChangesHelper(newState.routes, null);
      newState.routes = helper.removeGratisAndNoShow(
        action.payload.reservationId,
      );
      return newState;

    case AC.CHANGE_PRICE_RESERVATION_REQUEST_SUCCESS:
      var newState = merge({}, state, { sendingRequestReservation: false });
      var helper = new ReservationChangesHelper(newState.routes, null);
      newState.routes = helper.changeReservationPrice(
        action.payload.reservationId,
        action.payload.value
      );
      return newState;

    case AC.SET_FILTER_DRIVER_ID:
      return merge({}, state, { attedanceFilterDriverId: action.payload.id });

    case AC.REMOVE_RESERVATION_FROM_CHANGES:
      var newState = merge({}, state);
      var change = false;
      var r = newState.routesChanges[action.payload.routeId];
      if (r) {
        if (r.reservations) {
          if (r.reservations[action.payload.reservationId]) {
            change = true;
            delete r.reservations[action.payload.reservationId];
          }
        }
      }
      if (change) {
        LocalStorageHelper.saveRoutesChanges(newState.routesChanges);
      }
      return newState;

    case AC.REMOVE_ROUTE_FROM_CHANGES:
      var newState = merge({}, state);
      var change = false;
      var r = newState.routesChanges[action.payload.routeId];
      if (r) {
        delete r.global;
        LocalStorageHelper.saveRoutesChanges(newState.routesChanges);
      }
      return newState;

    case AC.SET_PLACE_IN_RESERVATION_FORM:
      var newState = merge({}, state);
      newState.reservationSelectedPlace = action.payload;
      return newState;

    case AC.CREATE_NOTIFICATION:
      if (state.notification.id === null) {
        var newId = 1;
      } else {
        var newId = state.notification.id + 1;
      }
      return merge({}, state, { notification: { id: newId, params: action.params } });

    case AC.SET_TRACKING_LOCALIZATION:
      var newState = merge({}, state);
      newState.driverLocation = action.payload;
      return newState;

    default:
      return state;
  }
}
