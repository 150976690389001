import React from 'react';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import {
  fetchRoutes, checkRoute, fetchBusStops,
  removeRouteFromChange, setPlaceInReservationForm, fetchPrices,
  createSuccessNotification
}
  from 'reducers/appActions';
import CalculatePriceHelper from 'utils/CalculatePriceHelper';
import { withStyles } from '@material-ui/styles';
import { toggleRoute } from 'reducers/appActions';
import { withRouter } from "react-router";
import ReservationForm from './ReservationForm';
import BusStopsHelper from 'utils/BusStopsHelper';
import GoogleMapReact from 'google-map-react';
import PlaceHelper from 'utils/PlaceHelper';
import { SubmissionError } from 'redux-form';
import { PathMatcher } from 'PathMatcher';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';

import { reset } from 'redux-form';

const styles = {};

const Marker = (props) => {
  return (<svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="36px"
    height="44px"
    viewBox="0 0 36 44"
    xmlSpace="preserve"
  >
    <path
      fill={props.fill}
      d={`M18,0C8.1,0,0,8.1,0,18c0,9.9,15.7,25.1,15.7,25.1c1.3,1.2,3.3,1.2,4.6,0c0,0,15.7-15.1,15.7-25.1
	    C36,8.1,27.9,0,18,0z`} />
  </svg>);
}

const MarkerComponent = ({ text, handleClick, fill }) => (
  <div onClick={handleClick} className="marker-container">
    <Marker fill={fill} />
    {text && (
      <div className="marker-container--centered">{text}</div>
    )}
  </div>
);

class CreateReservation extends React.Component {

  componentDidMount() {
    const { busStops, prices, fetchBusStops, fetchPrices } = this.props;
    if (busStops.length === 0) {
      fetchBusStops();
    }
    if (prices === null) {
      fetchPrices();
    }
  }

  componentDidUpdate(prevProps) {
    const { reservationSelectedPlace, prices, reservationForm, busStops } = this.props;
    var isBusStopSelected = this.isBusStopSelected();

    if (isBusStopSelected) {
      if (reservationForm.reservation.values.busStop) {
        var selectedBusStop = reservationForm.reservation.values.busStop.value;
        const { lat, lng } = busStops[selectedBusStop];
        this.map.setCenter({ lat: lat, lng: lng });
        this.map.setZoom(13);
      }
    } else {
      var isObjectChanged = PlaceHelper.isObjectChanged(
        prevProps.reservationSelectedPlace,
        reservationSelectedPlace
      );
      if (isObjectChanged && reservationSelectedPlace !== null) {
        var helper = new PlaceHelper(reservationSelectedPlace, prices);
        if (helper.isValid()) {
          const { lat, lng } = helper.getCoordinates();
          this.map.setCenter({ lat: lat, lng: lng });
          this.map.setZoom(13);
        }
      }
    }
  }

  handleSaveReservation = (values) => {
    const { reservationSelectedPlace, setPlaceInReservationForm, prices } = this.props;
    var params = {
      lat: null,
      lng: null,
      street: null,
      postcode: null,
      address: null,
      bus_stop: null
    };
    var errors = {};
    if (values.isBusStop === true) {
      var paramBusStopName = null;
      if (values.busStop) {
        paramBusStopName = values.busStop.value;
        params.bus_stop = values.busStop.value;
      }
      if (paramBusStopName === null) {
        errors.busStop = 'Wybierz przystanek';
      }
    } else {
      var helper = new PlaceHelper(reservationSelectedPlace, prices);
      if (helper.isValid(true)) {
        params.address = helper.getAddressName();
        const { lat, lng } = helper.getCoordinates();
        params.lat = lat;
        params.lng = lng;
        params.postcode = helper.getPostcode();
      } else {
        errors.common = 'Niepoprawny adres';
        //when is null (no address chosen) then validation is omitted
        if (reservationSelectedPlace == null) {
          setPlaceInReservationForm(false);
        }
      }
    }
    params.card_payment = false;

    var name = values.name;
    if (name) {
      if (name.length > 0) {
        params.name = name;
      } else {
        errors.name = "Niepoprawna nazwa";
      }
    } else {
      errors.name = "Niepoprawna nazwa";
    }

    var adultQuantity = 0;
    if (values.adultQuantity) {
      adultQuantity = parseInt(values.adultQuantity);
      if (Number.isInteger(adultQuantity)) {
        if (adultQuantity < 0) {
          errors.adultQuantity = "Liczba pasażerów nie może być ujemna";
        } else if (adultQuantity > 300) {
          errors.adultQuantity = "Liczba pasażerów zbyt duża";
        }
      } else {
        errors.adultQuantity = "Niepoprawna liczba";
      }
    }

    var childQuantity = 0;
    if (values.childQuantity) {
      childQuantity = parseInt(values.childQuantity);
      if (Number.isInteger(childQuantity)) {
        if (childQuantity < 0) {
          errors.childQuantity = "Liczba pasażerów nie może być ujemna";
        } else if (childQuantity > 300) {
          errors.childQuantity = "Liczba pasażerów zbyt duża";
        }
      } else {
        errors.childQuantity = "Niepoprawna liczba";
      }
    }

    var studentQuantity = 0;
    if (values.studentQuantity) {
      studentQuantity = parseInt(values.studentQuantity);
      if (Number.isInteger(studentQuantity)) {
        if (studentQuantity < 0) {
          errors.studentQuantity = "Liczba pasażerów nie może być ujemna";
        } else if (studentQuantity > 300) {
          errors.studentQuantity = "Liczba pasażerów zbyt duża";
        }
      } else {
        errors.studentQuantity = "Niepoprawna liczba";
      }
    }

    if (!values.adultQuantity && !values.childQuantity && !values.studentQuantity) {
      errors.adultQuantity = "Nie określono liczby pasażerów";
    } else if (adultQuantity === 0 && childQuantity === 0 && studentQuantity == 0 ) {
      errors.adultQuantity = "Nie określono liczby pasażerów";
    }

    params.adults = adultQuantity;
    params.children = childQuantity;
    params.students = studentQuantity;
    params.route_id = this.props.match.params.routeId;
    params.route_bus_id = this.props.match.params.routeBusId;

    if (Object.keys(errors).length > 0) {
      return Promise.reject(new SubmissionError(errors));
    } else {
      params.price = CalculatePriceHelper.calculatePrice(params, prices);

      return axios.post('/create-reservation', RequestHelper.signParams(params))
        .then(response => {
          this.props.setPlaceInReservationForm(null);
          var input = document.getElementById('pac-input');
          if(input){
            input.value = '';
          }
          this.props.reset('reservation');
          this.props.createSuccessNotification({
            message: "Rezerwacja została dodana"
          });
        })
        .catch(error => {
          console.log("ERRR", error)
          console.log(error)
          var isUnauthorized = RequestHelper.isUnauthorized(error);
          if (isUnauthorized) {
            this.props.history.push('/login');
          } else if (!RequestHelper.isConnection(error)) {
            alert("No connection!");
          } else {
            throw new SubmissionError({
              common: error.response.data.common,
            });
          }

        })
    }
  }

  isBusStopSelected = () => {
    const { reservationForm } = this.props;
    var isBusStopSelected = false;
    if (reservationForm.reservation && reservationForm.reservation.values) {
      isBusStopSelected = reservationForm.reservation.values.isBusStop;
    }
    return isBusStopSelected;
  }

  getSelectedBusStop = () => {
    const { reservationForm } = this.props;
    if (reservationForm.reservation && reservationForm.reservation.values) {
      return reservationForm.reservation.values.busStop;
    } else {
      return null;
    }
  }

  apiIsLoaded = (map, maps) => {
    this.map = map;
    this.maps = maps;
  }

  renderMarker = () => {
    const { reservationSelectedPlace, prices, busStops } = this.props;
    var isBusSelected = this.isBusStopSelected();
    if (isBusSelected) {
      var busStop = this.getSelectedBusStop();
      if (busStop) {
        const { lat, lng } = busStops[busStop.value];
        return (
          <MarkerComponent
            lat={lat}
            lng={lng}
            text=''
            fill="#ff764b"
          />
        );
      } else {
        return null;
      }
    } else {
      var helper = new PlaceHelper(reservationSelectedPlace, prices);
      if (helper.isValid()) {
        const { lat, lng } = helper.getCoordinates();
        return (
          <MarkerComponent
            lat={lat}
            lng={lng}
            text=''
            fill="#ff764b"
          />
        );
      } else {
        return null;
      }
    }
  }

  render() {
    var busStopSelectData = BusStopsHelper.parseDataToReactSelect(this.props.busStops);
    var centerCoords = {
      lat: 59.913020,
      lng: 10.752581
    };
    var backUrl = PathMatcher.routeShowPath({ id: this.props.match.params.routeId });
    return (
      <Layout
        label="Dodaj rezerwację"
        backUrl={backUrl}
      >
        <div style={{ display: "flex" }}>
          <div className="inline">
            <ReservationForm
              onSubmit={this.handleSaveReservation}
              busStopsData={busStopSelectData}
            />
          </div>
          <div className="inline" style={{ height: '500px', width: '55vw', position: "absolute", right: "0" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyBM28iY5Jv9N0a-aLbEZEZ9npAg44oxvBk' }}
              defaultCenter={centerCoords}
              defaultZoom={11}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
            >
              {this.renderMarker()}
            </GoogleMapReact>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busStops: state.app.busStops,
    prices: state.app.prices,
    reservationForm: state.form,
    reservationSelectedPlace: state.app.reservationSelectedPlace
  };
};

const mapDispatchToProps = {
  removeRouteFromChange,
  toggleRoute,
  fetchRoutes,
  checkRoute,
  fetchBusStops,
  setPlaceInReservationForm,
  reset,
  fetchPrices,
  createSuccessNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateReservation))
);
