import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import carSeatPad from 'components/assets/icons/car_seat_pad.png';
import cradle from 'components/assets/icons/cradle.png';
import seatWithoutSeatBelts from 'components/assets/icons/seat_without_seat_belts.png';
import seatWithSeatBelts from 'components/assets/icons/seat_with_seat_belts.png';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import { toggleReservation } from 'reducers/appActions';
import { withRouter } from "react-router";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import ChangeDetector from 'components/common/ChangeDetector';
import ReservationItemOptions from './ReservationItemOptions';
import ReservationItemActions from './ReservationItemActions';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PhoneIcon from '@material-ui/icons/Phone';
import Avatar from '@material-ui/core/Avatar';
import ReservationHelper from 'utils/ReservationHelper';
import { PathMatcher } from 'PathMatcher';
import CallSplitIcon from '@material-ui/icons/CallSplit';

const styles = {
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  kids: {
    color: "#4593ea"
  },
  price: {
    fontSize: "17px",
    color: "green"
  },
  ft15: {
    fontSize: "15px"
  },
  ft14: {
    fontSize: "14px"
  },
  ft13: {
    fontSize: "13px"
  },
  ft13bold: {
    fontSize: "13px",
    fontWeight: "bold"
  },
  ft13blue: {
    fontSize: "12px",
    color: "#1968bf"
  },
  blue: {
    backgroundColor: "#2195f3",
  },
  notificationButton: {
    fontSize: "10px",
    marginBottom: "5px",
    padding: "4px",
    minWidth: "40px"
  },
  actionHolder: {
    display: "inline-block",
    position: "relative"
  },
  phoneLink: {
    textDecoration: "none",
    color: "inherit"
  },
  block: {
    display: "block"
  }
};

class ReservationItem extends React.Component {

  handleClickCheckbox = (e) => {
    this.props.toggleReservation(this.props.data.id);
  }

  getIconsSeats() {
    var seats = this.props.data.reservation_seats;
    var results = [];
    if (seats.length > 0) {
      for (var i = 0; i < seats.length; i++) {
        var seat = seats[i];
        if (seat.seat_type === "NO_NEED_CAR_SEAT") {
          continue;
        }
        var icon = "";
        var name = "";
        switch (seat.seat_type) {
          case "CAR_SEAT_PAD":
            icon = carSeatPad;
            name = "Podkładka";
            break;
          case "SEAT_WITHOUT_SEAT_BELTS":
            icon = seatWithoutSeatBelts;
            name = "Siedzący bez pasów";
            break;
          case "SEAT_WITH_SEAT_BELTS":
            icon = seatWithSeatBelts;
            name = "Siedzący z pasami";
            break;
          case "CRADLE":
            icon = cradle;
            name = "Kołyska";
            break;
          default:
            icon = ""
            name = "";
        }
        if (seat.amount > 0) {
          results.push(
            <Tooltip enterTouchDelay={0} title={name} key={i}>
              <div className="inlineBlock" style={{ position: "relative", marginLeft: "3px" }}>
                <img src={icon} alt={name} />
                <span style={{ position: "relative", top: "-5px" }}>
                  {seat.amount}
                </span>
              </div>
            </Tooltip>
          )
        }
      }
      return results;
    } else {
      return null;
    }
  }

  handleClose = () => {
    this.setState({ showMenu: false });
  }

  isReservationChanged = () => {
    var routeId = this.props.match.params.id;
    const { id } = this.props.data;
    const { routesChanges } = this.props;
    var route = routesChanges[routeId];
    if (route) {
      if (route.reservations) {
        if (route.reservations[id]) {
          return true;
        }
      }
    }
    return false;
  }

  handleClickAction = (e) => {
    e.preventDefault();
    const { id } = this.props.data;
    var url = PathMatcher.editReservationPath({
      routeId: this.props.match.params.id,
      reservationId: id
    });
    this.props.history.push(url);
  }

  render() {
    const { id } = this.props.data;
    const { classes, busStops, completedReservations } = this.props;
    var isReservationChanged = this.isReservationChanged();
    var rs = new ReservationHelper(this.props.data, busStops);
    var changeDetectorProps = {
      routeId: this.props.match.params.id,
      reservationId: id
    };

    return (
      <Grid
        className={`${classes.rowItem} reservationItem`}
        container
        spacing={0}
        alignContent="center"
      >
        <Grid item xs={2}>
          <Checkbox
            style={{ padding: "0px 5px 5px 5px" }}
            checked={rs.isCompletedReservation(completedReservations)}
            onChange={this.handleClickCheckbox}
          />
          {rs.isSetBusArrival() && (
            <ChangeDetector
              field={["bus_arrival", "flight"]}
              {...changeDetectorProps}
            >
              <Typography className={classes.ft15} variant="subtitle1" gutterBottom>
                {rs.formattedBusArrival()}
              </Typography>
            </ChangeDetector>
          )}
          <Typography
            className={classes.ft13}
            variant="subtitle1"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            <p
              style={{ margin: "0", position: "relative" }}
              dangerouslySetInnerHTML={{ __html: rs.destination() }}
            />
          </Typography>
          {isReservationChanged && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className={classes.notificationButton}
            >
              Akt
            </Button>
          )}
        </Grid>

        <Grid item xs={3}>
          <Typography className={classes.ft13bold} variant="subtitle1" gutterBottom>
            {rs.customerName()}
          </Typography>
          <ChangeDetector
            field={["bus_stop", "place"]}
            {...changeDetectorProps}
          >
            <Typography className={classes.ft13} variant="subtitle1" gutterBottom>
              {rs.pickupName()}
            </Typography>
          </ChangeDetector>
          <Typography className={classes.ft13} variant="subtitle1">
            {rs.postcode()}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <ChangeDetector field={["adult_quantity", "child_quantity"]} {...changeDetectorProps}>
            <Typography className={classes.kids} align="center" variant="subtitle2" gutterBottom>
              {(rs.adultQuantity() > 0) && (
                <Fragment> Dorośli: {rs.adultQuantity()}
                  <br />
                </Fragment>
              )}
              {(rs.childQuantity() > 0) && (
                <Fragment> Dzieci: {rs.childQuantity()}
                  <br />
                </Fragment>
              )}
              {(rs.studentQuantity() > 0) && (
                <Fragment> Studenci: {rs.studentQuantity()}
                  <br />
                </Fragment>
              )}
              {(rs.teenagerQuantity() > 0) && (
                <Fragment> Nastolatki: {rs.teenagerQuantity()}
                  <br />
                </Fragment>
              )}
              {(rs.seniorQuantity() > 0) && (
                <Fragment> Seniorzy: {rs.seniorQuantity()}
                  <br />
                </Fragment>
              )}
            </Typography>
          </ChangeDetector>
        </Grid>

        <Grid item xs={2} style={{ textAlign: "center" }}>
          {this.getIconsSeats() ?
            <React.Fragment>
              {this.getIconsSeats()} <br />
            </React.Fragment>
            :
            null
          }
          <Typography className={classes.ft13} variant="body2">
            {rs.isFlyo() &&
              <React.Fragment>
                <span>[FLYO]</span> <br />
              </React.Fragment>
            }
            <span style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: rs.timetableTimeText() }} />
            {rs.additionalInformation()}
          </Typography>

          <Typography className={classes.ft13blue} variant="body2">
            {rs.liftInfo()}
          </Typography>

          {rs.isNoShow() && (
            <Typography className={classes.block} align="center" variant="caption">
              NO SHOW
            </Typography>
          )}
          {rs.isGratis() && (
            <Typography className={classes.block} align="center" variant="caption">
              GRATIS
            </Typography>
          )}
        </Grid>

        <Grid item xs={1} style={{ display: 'grid', textAlign: "center" }}>
          {
            rs.isPhoneDefined()
              ?
              <div style={{ margin: '0 auto', top: '-15%' }} className={classes.actionHolder}>
                <a
                  className={classes.phoneLink}
                  href={`tel:${rs.customerPhone()}`}
                >
                  <Avatar className={classes.blue}>
                    <PhoneIcon />
                  </Avatar>
                </a>
              </div>
              :
              null
          }
          <div className={classes.actionHolder} style={{ top: "-5pxx", marginLeft: "0pxx" }}>
            <ReservationItemOptions
              showMarkerOnMap={this.props.showMarkerOnMap}
              reservation={this.props.data}
            />
          </div>
        </Grid>

        <Grid item xs={2} style={{ textAlign: "center", marginBottom: "10px" }}>
          <ChangeDetector field="price" {...changeDetectorProps}>
            <Typography className={classes.price} align="center" variant="h6">
              {rs.priceText()} NOK
              {rs.isPaid() && (
                <Tooltip enterTouchDelay={0} title={"Reserwacja opłacona"}>
                  <MoneyIcon className="is-paid" />
                </Tooltip>
              )}
              {rs.isPaidUsingCard() && (
                <Tooltip enterTouchDelay={0} title={"Zapłacone kartą"}>
                  <CreditCardIcon className="is-paid" />
                </Tooltip>
              )}
              {rs.isSplittedCardPayment() && (
                <Tooltip enterTouchDelay={0} title={"Rozbita płatność"}>
                  <CallSplitIcon className="is-splitted" />
                </Tooltip>
              )}
            </Typography>
          </ChangeDetector>
          <div>
            <Button
              className="center-block"
              onClick={this.handleClickAction}
              size="small"
              variant="contained"
            >
              Edycja
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    completedReservations: state.app.completedReservations,
    busStops: state.app.busStops,
    routesChanges: state.app.routesChanges
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleReservation })(withStyles(styles)(ReservationItem))
);
