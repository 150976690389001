import React from 'react';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import { fetchAttendanceList, clearAttendanceList } from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import MaterialTable from 'material-table';
import AttendanceMap from './AttendanceMap';
import PassengersHelper from 'utils/PassengersHelper';
import find from 'lodash/find';
import Button from '@material-ui/core/Button';
import DriverSelect from './DriverSelect';
import FlightBar from 'components/common/FlightBar';
import { PathMatcher } from 'PathMatcher';

import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const styles = {
  contained: {
    marginLeft: "15px",
    backgroundColor: "#4593ea",
    color: "white",
    "&:hover": {
      backgroundColor: "#1968bf",
    }
  },
  activeAppBarButton: {
    backgroundColor: "#1968bf!important",
    color: "white!important"
  },
};

class AttendanceList extends React.Component {

  state = {
    selectedDrivers: [],
    listView: true,
    columns: [
      {
        title: '#',
        field: 'order',
        headerStyle: {
          padding: "5px",
        },
      },
      {
        title: 'Lot',
        field: 'flight',
        headerStyle: {
          padding: "5px",
        },
      },
      {
        title: 'Nazwa',
        field: 'customer_name',
        headerStyle: {
          padding: "5px",
        },
      },
      {
        title: 'Adres',
        field: 'address',
        headerStyle: {
          padding: "5px",
        },
      },
      {
        title: 'Nr telefonu',
        field: 'customer_phone',
        headerStyle: {
          padding: "5px",
        },
      },
      {
        title: 'Kierowca',
        field: 'driver_name',
        headerStyle: {
          padding: "5px",
        },
      }
    ],
  }

  componentDidMount() {
    this.props.clearAttendanceList();
    const routeId = this.props.match.params.routeId;
    this.props.fetchAttendanceList({ routeId });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.attedanceList === null &&
      this.props.attedanceList !== null) {
      var drivers = [];
      this.props.attedanceList.drivers.forEach((d) => {
        drivers.push(d.name);
      });
      this.setState({ selectedDrivers: drivers });
    }
  }

  toggleDriver = (driverName) => {
    var isSelected = this.state.selectedDrivers.includes(driverName);
    if (isSelected) {
      var newSelectedDrivers = this.state.selectedDrivers.filter(e => e !== driverName);
    } else {
      var newSelectedDrivers = [...this.state.selectedDrivers];
      newSelectedDrivers.push(driverName);
    }
    this.setState({ selectedDrivers: newSelectedDrivers });
  }

  refreshAction = () => {
    const routeId = this.props.match.params.routeId;
    this.props.fetchAttendanceList({ routeId });
  }

  changeViewTypeToList = () => {
    this.setState({ listView: true });
  }

  changeViewTypeToMap = () => {
    this.setState({ listView: false });
  }

  appBarElements = () => {
    var id = this.props.match.params.routeId;
    if (this.props.routes.length === 0) {
      return null;
    }
    var route = find(this.props.routes, function (o) { return o.id === parseInt(id); });
    const { classes } = this.props;
    var activeListClass = this.state.listView ? classes.activeAppBarButton : '';
    var activeMapClass = !this.state.listView ? classes.activeAppBarButton : '';
    return (
      <React.Fragment>
        <FlightBar
          settlementStatus={route.route_buses[0].settlement_status}
          flightDirection={route.flight_direction}
          flights={route.flights}
          isLift={route.route_buses[0].is_lift}
        />
        <Button
          className={`${classes.contained} ${activeListClass}`}
          size="small"
          variant="contained"
          onClick={this.changeViewTypeToList}
        >
          Lista
        </Button>
        <Button
          className={`${classes.contained} ${activeMapClass}`}
          size="small"
          variant="contained"
          onClick={this.changeViewTypeToMap}
        >
          Mapa
        </Button>
      </React.Fragment >
    );
  }

  render() {
    const { fetchingAttendanceList, attedanceList, attedanceFilterDriverId } = this.props;
    const routeId = this.props.match.params.routeId;
    var drivers = PassengersHelper.parseData(attedanceList, attedanceFilterDriverId);
    var titleComponent = (
      <React.Fragment>
        <p className="inline">Pasażerowie</p>
        <DriverSelect />
      </React.Fragment>
    );
    var backUrl = PathMatcher.routeShowPath({ id: routeId });

    return (
      <Layout
        elements={this.appBarElements()}
        label="Lista obecności"
        isLoading={fetchingAttendanceList}
        refresh={this.refreshAction}
        backUrl={backUrl}
        sizeh6="15px"
      >
        {
          this.state.listView
            ?
            <div className="attendace-holder">
              <MaterialTable
                columns={this.state.columns}
                data={drivers}
                icons={tableIcons}
                isLoading={fetchingAttendanceList}
                options={{
                  pageSize: 25,
                  pageSizeOptions: [25, 50, 70]
                }}
                title={titleComponent}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Szukaj"
                  }
                }}
              />
            </div>
            :
            <AttendanceMap
              toggleDriver={this.toggleDriver}
              selectedDrivers={this.state.selectedDrivers}
            />
        }
      </Layout>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    fetchingAttendanceList: state.app.fetchingAttendanceList,
    attedanceList: state.app.attedanceList,
    attedanceFilterDriverId: state.app.attedanceFilterDriverId,
    routes: state.app.routes
  };
};

export default withRouter(
  connect(mapStateToProps, { fetchAttendanceList, clearAttendanceList })(withStyles(styles)(AttendanceList))
);
