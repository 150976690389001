import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import { toggleRoute } from 'reducers/appActions';
import { withRouter } from "react-router";
import PhoneIcon from '@material-ui/icons/Phone';
import Avatar from '@material-ui/core/Avatar';

const styles = {
  blue: {
    backgroundColor: "#2195f3"
  },
  phone: {
    display: "inline-block",
    position: "relative",
    top: "6px"
  },
  text: {
    display: "inline-block",
    marginLeft: "20px"
  }
};

class RouteList extends React.Component {

  state = { tabIndex: 0 };

  handleChangeTab = (event, value) => {
    this.setState({ tabIndex: value });
  }

  render() {
    const { activeReservationsSize, activeReservations, liftsTabData,
      endedReservationsSize, endedReservations, classes } = this.props;
    const { tabIndex } = this.state;
    var showLiftTab = liftsTabData.length > 0;
    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs"
          >
            <Tab
              label={`W toku (${activeReservationsSize})`}
              id="full-width-tab-0"
              aria-controls='full-width-tabpanel-0'
            />
            <Tab
              label={`Zakończone (${endedReservationsSize})`}
              id="full-width-tab-1"
              aria-controls='full-width-tabpanel-1'
            />
            {showLiftTab && (
              <Tab
                label="Podwozy"
                id="full-width-tab-2"
                aria-controls='full-width-tabpanel-2'
              />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={tabIndex} index={0}>
          <div>
            {
              activeReservations.length === 0
                ?
                <Typography align="center">
                  Brak tras
                </Typography>
                :
                <div>
                  {activeReservations}
                </div>
            }
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div>
            {
              endedReservations.length === 0
                ?
                <Typography align="center">
                  Brak tras
                </Typography>
                :
                <div>
                  {endedReservations}
                </div>
            }
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <div>
            {liftsTabData.map((data, index) => {
              return (
                <div key={index}>
                  <a
                    className={classes.phone}
                    href={`tel:${data.driver.phone}`}
                  >
                    <Avatar className={classes.blue}>
                      <PhoneIcon />
                    </Avatar>
                  </a>
                  <p className={classes.text}>{data.text}</p>
                </div>
              );
            })}
          </div>
        </TabPanel>
      </React.Fragment>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    completedReservations: state.app.completedReservations
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleRoute })(withStyles(styles)(RouteList))
);
