import Axios from 'axios';
// import LocalStorageHelper from 'components/common/helpers/LocalStorageHelper';
// import { PANEL_URL } from 'components/windowEnvs';

const axios = Axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  crossDomain: true,
  withCredentials: true,
  baseURL: process.env.REACT_APP_PANEL_URL + '/api-v2'
});

axios.interceptors.request.use(
  function (config) {
    // const token = LocalStorageHelper.getToken();
    // if (token) {
    //   config.headers['X-AUTH-TOKEN'] = token;
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
