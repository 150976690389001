import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTrackingLocalication } from 'reducers/appActions';

class TrackingLocalization extends Component {

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(this.getCurrentLocation);
    } else {
      console.log("Geo Location not supported by browser");
    }
  }

  getCurrentLocation = (location) => {
    if (location && location.coords) {
      this.props.setTrackingLocalication({
        lat: location.coords.latitude,
        lng: location.coords.longitude
      })
    }
  }
  render() {
    return null;
  }
}

export default connect(null, {setTrackingLocalication})(TrackingLocalization);
