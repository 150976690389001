import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import arrivalIcon from 'components/assets/icons/route_arrival_enabled.png';
import departureIcon from 'components/assets/icons/route_departure_enabled.png';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import SettlementStatus from './SettlementStatus';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

const styles = {};

class FlightBar extends React.Component {

  getFlightName(flights) {
    if (!flights) {
      return "";
    }

    var result = [];
    var direction = "";
    var airport = flights[0].airport;
    for (var i = 0; i < flights.length; i++) {
      var flight = flights[i];
      result.push(flight.city.name)
      direction = flight.direction;
    }
    if (direction === "arrive") {
      return result.join(", ") + " - " + airport.name;
    } else {
      return airport.name + " - " + result.join(", ");
    }
  }

  getFlightsTimeLabel = (flights) => {
    var sortedFlights = sortBy(
      flights,
      function (o) { return new moment(o.date); }
    );
    var results = [];
    sortedFlights.forEach((f) => {
      var time = moment(f.date).tz('Europe/Warsaw').format("HH:mm");
      results.push(time);
    })
    return results.join(", ");
  }

  render() {
    const { flightDirection, isLift, flights, settlementStatus } = this.props;
    return (
      <div className="inline" style={{ position: "relative" }}>
        <div style={{ position: "absolute", left: "-60px", top: "6px" }}>
          <SettlementStatus status={settlementStatus} />
        </div>
        <div style={{ position: 'relative' }}>
          {
            isLift
              ?
              <DirectionsBusIcon className="routeIcon liftIcon routeIcon--moveLeft" />
              :
              <Fragment>
                {flightDirection === "arrive" && (
                  <img alt='arrive' className="routeIcon routeIcon--moveLeft" src={arrivalIcon} />
                )}
                {flightDirection === "flight" && (
                  <img alt='departure' className="routeIcon routeIcon--moveLeft" src={departureIcon} />
                )}
              </Fragment>
          }
          <p className="toolbar-p ft12">
            {this.getFlightName(flights)}
          </p>
          <p className="toolbar-p ft12">
            {this.getFlightsTimeLabel(flights)}
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FlightBar);
