import CalculatePriceHelper from 'utils/CalculatePriceHelper';
const GOOGLE_STREET_TYPE = 'street_number';
const GOOGLE_POSTALCODE_TYPE = 'postal_code';

class PlaceHelper {
  constructor(place, prices) {
    this.place = place;
    this.street = null;
    this.postcode = null;
    this.lat = null;
    this.lng = null;
    this.prices = prices;
    this.parseObject();
  }

  isValid = (forceValid = false) => {
    if (this.place === null && forceValid === false) {
      return true;
    } else {
      return this.getErrors().length === 0;
    }
  }

  getSingleError = () => {
    if (this.place === null) {
      return null;
    }
    var errors = this.getErrors();
    if (errors.length > 0) {
      return errors[0];
    } else {
      return null;
    }
  }

  getErrors = () => {
    var errors = [];
    if (this.street === null) {
      errors.push("Adres nie posiada numeru ulicy");
    }
    if (this.postcode === null) {
      errors.push("Adres nie posiada kodu pocztowego");
    } else {
      var isValid = CalculatePriceHelper.isValidPostcode(this.postcode, this.prices);
      if (!isValid) {
        errors.push("Kod pocztowy nie jest obsługiwany");
      }
    }
    if (this.lat === null || this.lng === null) {
      errors.push("Adres ma nieprawidłowe współrzędne");
    }
    return errors;
  }

  getCoordinates = () => {
    return { lat: this.lat, lng: this.lng };
  }

  getPostcode = () => {
    return this.postcode;
  }

  getAddressName = () => {
    return this.place.name;
  }

  parseObject() {
    if (this.place === null || this.place === false) {
      return;
    }

    //get street number and postcode
    var addressComponents = this.place.address_components;
    for (var i = 0; i < addressComponents.length; i++) {
      var acItem = addressComponents[i];
      if (acItem.types[0] === GOOGLE_STREET_TYPE) {
        this.street = acItem.short_name;
      }
      if (acItem.types[0] === GOOGLE_POSTALCODE_TYPE) {
        this.postcode = acItem.short_name;
      }
    }

    //get coordinates
    if (this.place.geometry) {
      if (this.place.geometry.location) {
        this.lat = this.place.geometry.location.lat();
        this.lng = this.place.geometry.location.lng();
      }
    }
  }

  static isObjectChanged(previusObject, newObject) {
    return JSON.stringify(previusObject) !== JSON.stringify(newObject);
  }

}

export default PlaceHelper;
