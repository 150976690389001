import moment from 'moment-timezone';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import MergeLiftsWithReservations from 'utils/MergeLiftsWithReservations';

class ReservationHelper {
  constructor(reservation, busStopsDictionary) {
    this.reservation = reservation;
    this.busStopsDictionary = busStopsDictionary;
    this.splitParams = null;
  }

  setSplitParams = (params) => {
    this.splitParams = params;
  }

  considerSpliting() {
    return this.splitParams !== null;
  }

  destination = () => {
    var destination = this.reservation.flight.city.name;
    var fligthTime = moment(this.reservation.flight.date).tz('Europe/Warsaw').format("HH:mm");
    return fligthTime + "<br /> " + destination;
  }

  isCompletedReservation = (completedReservations) => {
    return includes(completedReservations, this.reservation.id);
  }

  postcode = () => {
    if (this.reservation.bus_stop) {
      return null;
    } else {
      return this.reservation.address.post_code;
    }
  }

  isSetOrder = () => {
    return this.reservation.order !== null;
  }

  isSetPostCode = () => {
    return this.postcode() !== null;
  }

  order = () => {
    return this.reservation.order;
  }

  pickupName = () => {
    var pickupName = "";
    if (this.reservation.bus_stop) {
      var bustStopName = this.reservation.bus_stop;
      pickupName = this.reservation.bus_stop;
      if (this.busStopsDictionary[bustStopName]) {
        pickupName = this.busStopsDictionary[bustStopName].name;
      }
    } else {
      pickupName = this.reservation.address.name;
      pickupName = pickupName.replace(", Oslo, Norwegia", "");
    }
    return pickupName;
  }

  customerName = () => {
    return this.reservation.customer.name;
  }

  adultQuantity = () => {
    if (this.considerSpliting()) {
      return this.reservation.adult_quantity - this.splitParams.adults;
    } else {
      return this.reservation.adult_quantity;
    }
  }

  childQuantity = () => {
    if (this.considerSpliting()) {
      return this.reservation.child_quantity - this.splitParams.children;
    } else {
      return this.reservation.child_quantity;
    }
  }

  studentQuantity = () => {
    if (this.considerSpliting()) {
      return this.reservation.student_quantity - this.splitParams.students;
    } else {
      return this.reservation.student_quantity;
    }
  }

  seniorQuantity = () => {
    if (this.considerSpliting()) {
      return this.reservation.senior_quantity - this.splitParams.seniors;
    } else {
      return this.reservation.senior_quantity;
    }
  }

  teenagerQuantity = () => {
    if (this.considerSpliting()) {
      return this.reservation.teenager_quantity - this.splitParams.teenagers;
    } else {
      return this.reservation.teenager_quantity;
    }
  }

  additionalInformation = () => {
    return this.reservation.additional_information;
  }

  isFlyo = () => {
    return this.reservation.is_flyo_app;
  }

  timetableTimeText = () => {
    var time = this.reservation.timetable_time;
    if (time) {
      return  time + '<br>';
    } else {
      return '';
    }
  }

  isSetBusArrival = () => {
    return this.reservation.bus_arrival !== null;
  }

  isSplittedCardPayment = () => {
    return this.reservation.splitted_payment_amount !== null;
  }

  customerPhone = () => {
    return this.reservation.customer.phone
  }

  price = () => {
    if (this.considerSpliting()) {
      return this.reservation.price - this.splitParams.price;
    } else {
      return this.reservation.price;
    }
  }

  liftInfo = () => {
    if (this.reservation.is_lift && this.reservation.is_main_route_bus === false) {
      var info = [];
      info.push(this.reservation.driver_name);
      if (this.reservation.lift_meet) {
        var bs = this.busStopsDictionary[this.reservation.lift_meet];
        info.push(bs.name);
      }
      info.push(this.reservation.lift_comment);
      var data = filter(info, function (o) { return o !== ""; });
      return "Podwóz: " + data.join(", ");
    } else {
      return null;
    }
  }

  isPaid = () => {
    return this.reservation.is_paid;
  }

  isNoShow = () => {
    return this.reservation.status === 7;
  }

  isGratis = () => {
    return this.reservation.gratis === true;
  }

  isPriceChangedByDriver = () => {
    return this.reservation.price_changed_by_driver;
  }

  isPaidUsingCard = () => {
    return this.reservation.card_payment_in_bus === true;
  }

  isPhoneDefined = () => {
    return this.reservation.customer.phone !== null &&
      this.reservation.customer.phone != "";
  }

  priceText = () => {
    if (this.isNoShow() || this.isGratis()) {
      if (this.isPriceChangedByDriver()) {
        return this.price();
      } else {
        return 0;
      }
    } else {
      return this.price();
    }
  }

  formattedBusArrival = () => {
    return moment(this.reservation.bus_arrival).tz('Europe/Warsaw').format("HH:mm");
  }

  coordinates = () => {
    var lat, lng;
    var bs = this.reservation.bus_stop;
    if (bs) {
      lat = this.busStopsDictionary[bs].lat;
      lng = this.busStopsDictionary[bs].lng;
    } else {
      lat = this.reservation.address.lat;
      lng = this.reservation.address.lng;
    }
    return { lat, lng };
  }

  static countTotalPeopleInRoute(route) {
    var count = 0;
    var reservations = new MergeLiftsWithReservations(route).merge();
    for (var i = 0; i < reservations.length; i++) {
      var reservation = reservations[i];
      if (!reservation.no_show) {
        count += reservation.total_passengers_quantity;
      }
    }
    return count;
  }

}

export default ReservationHelper;
