import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import FormTextField from 'components/common/FormTextField';
import ErrorDisplay from 'components/common/ErrorDisplay';
import FormCheckboxField from 'components/common/FormCheckboxField';
import FormSelectField from 'components/common/FormSelectField';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import GoogleSearchPlaces from 'components/common/GoogleSearchPlaces';
import { setPlaceInReservationForm } from 'reducers/appActions';
import PlaceHelper from 'utils/PlaceHelper';
import CalculatePriceHelper from 'utils/CalculatePriceHelper';
import NumberCounter from 'components/common/NumberCounter';

class ReservationForm extends React.Component {

  componentDidMount(){
    this.props.initialize({name: "Guest"});
  }

  render() {
    const { handleSubmit, busStopsData, isBusStop, prices, busStop, adultQuantity,
      childQuantity, setPlaceInReservationForm, reservationSelectedPlace, studentQuantity } = this.props;
    var placeHelper = new PlaceHelper(reservationSelectedPlace, prices);

    var reservationObject = {
      bus_stop: busStop ? busStop.value : null,
      postcode: placeHelper.getPostcode(),
      adults: adultQuantity ? adultQuantity : 0,
      children: childQuantity ? childQuantity : 0,
      students: studentQuantity ? studentQuantity : 0
    }
    var price = CalculatePriceHelper.calculatePrice(reservationObject, prices);
    return (
      <form
        onSubmit={handleSubmit}
        style={{ marginLeft: "20px", width: "40vw" }}
        className=""
        action="#"
        method="post"
      >
        <div>
          <Field
            type="text"
            name="name"
            component={FormTextField}
            label="Nazwa"
          />
        </div>
        <div>
          <Field
            type="text"
            name="isBusStop"
            component={FormCheckboxField}
            label="Przystanek"
          />
        </div>
        {
          isBusStop
            ?
            <div style={{ marginTop: '20px' }}>
              <Field
                name="busStop"
                suggestions={busStopsData}
                component={FormSelectField}
                label="Przystanki"
              />
            </div>
            :
            <div>
              <GoogleSearchPlaces
                error={placeHelper.getSingleError()}
                selectedPlace={setPlaceInReservationForm}
              />
            </div>
        }
        <NumberCounter
          label={'Osoby dorosłe'}
          value={reservationObject.adults}
          onChange={(v) => this.props.change('adultQuantity', v)}
        />
        <Field
          name="adultQuantity"
          component={ErrorDisplay}
        />
        <NumberCounter
          label={'Dzieci'}
          value={reservationObject.children}
          onChange={(v) => this.props.change('childQuantity', v)}
        />
        <Field
          name="childQuantity"
          component={ErrorDisplay}
        />
        <NumberCounter
          label={'Studenci'}
          value={reservationObject.students}
          onChange={(v) => this.props.change('studentQuantity', v)}
        />
        <Field
          name="studentQuantity"
          component={ErrorDisplay}
        />
        <div>
          <p>Cena: {price ? price + " NOK" : 'nie podano wymaganych parametrów'}</p>
        </div>
        <Field
          name="common"
          component={ErrorDisplay}
        />
        <Button
          size="small"
          variant="contained"
          type="submit"
        >
          Zapisz
        </Button>
      </form>
    );
  }
}

ReservationForm = reduxForm({
  // a unique name for the form
  form: 'reservation'
})(ReservationForm);

const selector = formValueSelector('reservation');

ReservationForm = connect(state => {
  // can select values individually
  const isBusStop = selector(state, 'isBusStop');
  const busStop = selector(state, 'busStop');
  const adultQuantity = selector(state, 'adultQuantity');
  const childQuantity = selector(state, 'childQuantity');
  const studentQuantity = selector(state, 'studentQuantity');
  return {
    isBusStop,
    busStop,
    adultQuantity,
    childQuantity,
    studentQuantity,
    reservationSelectedPlace: state.app.reservationSelectedPlace,
    prices: state.app.prices
  }
}, { setPlaceInReservationForm })(ReservationForm)

export default ReservationForm;
