
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// docs:
// https://material-ui.com/components/text-fields/

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

// const classes = useStyles();

class FormCheckboxField extends React.Component {

  render() {
    const { input, type, label, meta: { touched, invalid, error }, ...custom } = this.props;
    const isError = error ? true : false;
    return (
      <FormControl className={useStyles.formControl} error={isError}>
        <FormControlLabel
          control={
            <Checkbox
              label={label}
              checked={input.value ? true : false}
              onChange={input.onChange}
            />
          }
          label={label}
        />
      </FormControl>
    );
  }
}

export default FormCheckboxField;
