import React from 'react';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import {
  fetchRoutes, checkRoute, fetchBusStops, removeRouteFromChange,
  fetchPrices, createErrorNotification, createSuccessNotification
} from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { toggleRoute } from 'reducers/appActions';
import { withRouter } from "react-router";
import find from 'lodash/find';
import Button from '@material-ui/core/Button';
import FlightBar from 'components/common/FlightBar';
import { PathMatcher } from 'PathMatcher';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import SettlementRouteHelper from 'utils/SettlementRouteHelper';
import RequestHelper from 'utils/RequestHelper';
import axios from 'AppRequest';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment-timezone';

const styles = {
  white: {
    color: 'white'
  },
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  activeAppBarButton: {
    backgroundColor: "#1968bf!important",
    color: "white!important"
  },
  mLeft: {
    marginLeft: "10px"
  },
  contained: {
    marginLeft: "15px",
    backgroundColor: "#4593ea",
    color: "white",
    "&:hover": {
      backgroundColor: "#1968bf",
    }
  },
  actionToolbar: {
    backgroundColor: "#6eb2ff"
  },
  rightIcon: {
    marginLeft: "10px",
  },
  ft12: {
    fontSize: "12px"
  }
};

class SettlementPage extends React.Component {

  state = {
    costs: 0,
    comment: "",
    error: null,
    startAt: null,
    endAt: null,
  };

  componentDidMount() {
    this.props.fetchRoutes();
  }

  updateSettlement = (e) => {
    if (this.state.costs < 0) {
      this.setState({ error: "Koszt nie może być ujemny" });
      return;
    }
    e.preventDefault();
    var routeBus = this.getRouteBus();
    var helper = new SettlementRouteHelper(this.getRoute());
    var params = {
      route_bus_id: routeBus.id,
      online_amount: helper.paidOnline,
      using_card_amount: helper.paidUsingCard,
      cash_amount: helper.totalCash,
      costs: parseInt(this.state.costs),
      comment: this.state.comment,
      start_at: this.state.startAt,
      end_at: this.state.endAt
    };

    axios.post('/update-settlement', RequestHelper.signParams(params))
      .then(response => {
        this.props.createSuccessNotification({
          message: "Rozliczenie zostało wysłane!"
        });
        this.props.history.push(PathMatcher.homepagePath());
      })
      .catch(error => {
        this.props.createErrorNotification({
          message: error.response.data.common
        })
        console.error(error)
      })
  }

  handleCostsChange = (e) => {
    this.setState({ costs: e.target.value });
  }

  getRoute = () => {
    const { match, routes } = this.props;
    var routeId = match.params.routeId;
    return find(routes, function (o) { return o.id === parseInt(routeId); });
  }

  getRouteBus = () => {
    var route = this.getRoute();
    return route.route_buses[0];
  }

  appBarElements = () => {
    if (this.props.routes.length === 0) {
      return null;
    }
    var route = this.getRoute();
    return (
      <FlightBar
        settlementStatus={route.route_buses[0].settlement_status}
        flightDirection={route.flight_direction}
        flights={route.flights}
        isLift={route.route_buses[0].is_lift}
      />
    );
  }

  refreshAction = () => {
    this.props.fetchRoutes();
  }

  handleChangeComment = (e) => {
    this.setState({ comment: e.target.value });
  }

  getStartAtMoment = () => {
    var routeBus = this.getRouteBus();
    if (routeBus.routes_times && routeBus.routes_times.startAt) {
      return moment(routeBus.routes_times.startAt).tz('Europe/Warsaw');
    } else {
      return null;
    }
  }

  getEndAtMoment = () => {
    var routeBus = this.getRouteBus();
    if (routeBus.routes_times && routeBus.routes_times.endAt) {
      return moment(routeBus.routes_times.endAt).tz('Europe/Warsaw');
    } else {
      return null;
    }
  }

  handleChangeStartAt = (value) => {
    if (value) {
      this.setState({ startAt: value.format("HH:mm") });
    }
  }

  handleChangeEndAt = (value) => {
    if (value) {
      this.setState({ endAt: value.format("HH:mm") });
    }
  }

  render() {
    const routeId = this.props.match.params.routeId;
    var backUrl = PathMatcher.routeShowPath({ id: routeId });
    var helper = new SettlementRouteHelper(this.getRoute());
    return (
      <Layout
        label="Rozliczenie trasy"
        isLoading={false}
        elements={this.appBarElements()}
        backUrl={backUrl}
        refresh={this.refreshAction}
        sizeh6="15px"
      >
        <div style={{ padding: "0px 25px 20px 25px" }}>
          <div style={{ textAlign: "center" }}>
            <p className="inlineBlock" style={{ padding: "0px 10px" }}>
              {'Rozpoczęcie: '}
              <TimePicker
                inputReadOnly={true}
                style={{ width: 100 }}
                showSecond={false}
                defaultValue={this.getStartAtMoment()}
                onChange={this.handleChangeStartAt}
              />
            </p>
            <p className="inlineBlock" style={{ padding: "0px 10px" }}>
              {'Zakończenie: '}
              <TimePicker
                inputReadOnly={true}
                style={{ width: 100 }}
                showSecond={false}
                defaultValue={this.getEndAtMoment()}
                onChange={this.handleChangeEndAt}
              />
            </p>
          </div>
          <h3>Pasażerowie</h3>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Typ</TableCell>
                  <TableCell align="right">Osoby dorosłe</TableCell>
                  <TableCell align="right">Dzieci</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Pasażerowie</TableCell>
                  <TableCell align="right">{helper.adultsOnBoard}</TableCell>
                  <TableCell align="right">{helper.childrenOnBoard}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">No show</TableCell>
                  <TableCell align="right">{helper.adultsNoShow}</TableCell>
                  <TableCell align="right">{helper.childrenNoShow}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Gratis</TableCell>
                  <TableCell align="right">{helper.adultsGratis}</TableCell>
                  <TableCell align="right">{helper.childrenGratis}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <h3>Finanse</h3>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Typ</TableCell>
                  <TableCell align="right">Kwota (NOK)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Opłacone online</TableCell>
                  <TableCell align="right">{helper.paidOnline}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Zapłacone gotówką</TableCell>
                  <TableCell align="right">{helper.totalCash}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Zapłacone kartą</TableCell>
                  <TableCell align="right">{helper.paidUsingCard}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <div style={{ textAlign: "center", margin: "20px" }}>
            <Paper>
              <TextField
                style={{ display: "inline-block" }}
                error={false}
                id="standard-number"
                label="Koszty"
                value={this.state.costs}
                onChange={this.handleCostsChange}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
              <TextField
                onChange={this.handleChangeComment}
                style={{ marginLeft: "20px", width: "180px" }}
                InputProps={{ style: { fontSize: 13 } }}
                id="outlined-multiline-static"
                label="Komentarz"
                multiline
                margin="normal"
                variant="outlined"
                value={this.state.comment}
              />
              <p style={{ padding: "0px 0px 20px 0px", textAlign: "center" }}>
                Gotówka w rozliczeniu: {helper.totalCashInBus(this.state.costs)} NOK
              </p>
            </Paper>
            <Button
              onClick={this.updateSettlement}
              size="small"
              color="primary"
              variant="contained"
            >
              Wyślij rozliczenie
          </Button>
            {this.state.error && (
              <p style={{ color: "red" }}>{this.state.error}</p>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    fetchingRoutes: state.app.fetchingRoutes,
  };
};

const mapDispatchToProps = {
  removeRouteFromChange,
  toggleRoute,
  fetchRoutes,
  checkRoute,
  fetchBusStops,
  fetchPrices,
  createErrorNotification,
  createSuccessNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettlementPage))
);
