const UID = "app_uid";
const SALT = "app_salt";
const ROUTES = "app_routes";
const COMPLETED_ROUTES = "app_completed_routes";
const COMPLETED_RESERVATIONS = "app_completed_reservations";
const BUS_STOPS = "app_bus_stops";
const ROUTES_CHANGES = "app_routes_changes";
const PRICES = "app_prices";

class LocalStorageHelper {

  static saveUid(value) {
    window.localStorage.setItem(UID, value);
  }

  static removeUid() {
    window.localStorage.removeItem(UID);
  }

  static getUid() {
    return window.localStorage.getItem(UID);
  }

  static saveSalt(value) {
    window.localStorage.setItem(SALT, value);
  }

  static removeSalt() {
    window.localStorage.removeItem(SALT);
  }

  static getSalt() {
    return window.localStorage.getItem(SALT);
  }

  static savePrices(value) {
    window.localStorage.setItem(PRICES, JSON.stringify(value));
  }

  static getPrices(defaultValue) {
    var result = window.localStorage.getItem(PRICES);
    if (result) {
      return JSON.parse(result);
    } else {
      return defaultValue;
    }
  }

  static saveRoutes(value) {
    window.localStorage.setItem(ROUTES, JSON.stringify(value));
  }

  static getRoutes(defaultValue) {
    var result = window.localStorage.getItem(ROUTES);
    if (result) {
      return JSON.parse(result);
    } else {
      return defaultValue;
    }
  }

  static saveBusStops(value) {
    window.localStorage.setItem(BUS_STOPS, JSON.stringify(value));
  }

  static getBusStops(defaultValue) {
    var result = window.localStorage.getItem(BUS_STOPS);
    if (result) {
      return JSON.parse(result);
    } else {
      return defaultValue;
    }
  }

  static saveCompletedRoutes(value) {
    window.localStorage.setItem(COMPLETED_ROUTES, JSON.stringify(value));
  }

  static getCompletedRoutes(defaultValue) {
    var result = window.localStorage.getItem(COMPLETED_ROUTES);
    if (result) {
      return JSON.parse(result);
    } else {
      return defaultValue;
    }
  }

  static saveCompletedReservations(value) {
    window.localStorage.setItem(COMPLETED_RESERVATIONS, JSON.stringify(value));
  }

  static getCompletedReservations(defaultValue) {
    var result = window.localStorage.getItem(COMPLETED_RESERVATIONS);
    if (result) {
      return JSON.parse(result);
    } else {
      return defaultValue;
    }
  }

  static saveRoutesChanges(value) {
    window.localStorage.setItem(ROUTES_CHANGES, JSON.stringify(value));
  }

  static getRoutesChanges(defaultValue) {
    var result = window.localStorage.getItem(ROUTES_CHANGES);
    if (result) {
      return JSON.parse(result);
    } else {
      return defaultValue;
    }
  }

  static isTokenData(){
    return LocalStorageHelper.getUid() && LocalStorageHelper.getSalt();
  }

}

export default LocalStorageHelper;
