import React from 'react';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import { fetchDriversList } from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import MaterialTable from 'material-table';

import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const styles = {
  contained: {
    marginLeft: "15px",
    backgroundColor: "#4593ea",
    color: "white",
    "&:hover": {
      backgroundColor: "#1968bf",
    }
  },
  activeAppBarButton: {
    backgroundColor: "#1968bf!important",
    color: "white!important"
  },
};

class DriversPage extends React.Component {

  state = {
    columns: [
      { title: 'Nazwa', field: 'name' },
      {
        title: 'Telefon',
        field: 'phone',
        render: rowData => <a href={`tel:${rowData.phone}`}>{rowData.phone}</a>
      }
    ],
  }

  componentDidMount() {
    this.props.fetchDriversList();
  }

  refreshAction = () => {
    this.props.fetchDriversList();
  }

  render() {
    const { fetchingDriverList, driversList } = this.props;
    var titleComponent = (
      <React.Fragment>
        <p className="inline">Kierowcy</p>
      </React.Fragment>
    );

    return (
      <Layout
        label="Lista kierowców"
        isLoading={fetchingDriverList}
        refresh={this.refreshAction}
        backUrl={'/'}
      >
        <MaterialTable
          columns={this.state.columns}
          data={driversList}
          icons={tableIcons}
          isLoading={fetchingDriverList}
          options={{
            pageSize: 35,
            pageSizeOptions: [35, 50, 70]
          }}
          title={titleComponent}
          localization={{
            toolbar: {
              searchPlaceholder: "Szukaj"
            }
          }}
        />
      </Layout>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    fetchingDriverList: state.app.fetchingDriverList,
    driversList: state.app.driversList,
  };
};

export default withRouter(
  connect(mapStateToProps, { fetchDriversList })(withStyles(styles)(DriversPage))
);
