import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = {
  fw: {
    width: "100%"
  }
};

class ErrorDisplay extends React.Component {
  render() {
    const { meta: { error } } = this.props;
    const isError = error ? true : false;
    if (isError) {
      return (
        <p style={{ color: 'red' }}>{error}</p>
      );
    } else {
      return null
    }
  }
}

export default withStyles(styles)(ErrorDisplay);;
