import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { toggleRoute } from 'reducers/appActions';
import { withRouter } from "react-router";
import GoogleMapReact from 'google-map-react';
import MarkerData from './MarkerData';

const styles = {
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  price: {
    color: "green"
  },
  kids: {
    color: "#4593ea"
  },
  ft18: {
    fontSize: "18px"
  },
  ft16: {
    fontSize: "16px"
  }
};

const Marker = (props) => {
  return (<svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="36px"
    height="44px"
    viewBox="0 0 36 44"
    xmlSpace="preserve"
  >
    <path
      fill={props.fill}
      d={`M18,0C8.1,0,0,8.1,0,18c0,9.9,15.7,25.1,15.7,25.1c1.3,1.2,3.3,1.2,4.6,0c0,0,15.7-15.1,15.7-25.1
	    C36,8.1,27.9,0,18,0z`} />
  </svg>);
}

const MarkerComponent = ({ text, handleClick, fill }) => (
  <div onClick={handleClick} className="marker-container">
    <Marker fill={fill} />
    {text && (
      <div className="marker-container--centered">{text}</div>
    )}
  </div>
);

class RouteMap extends React.Component {

  onChildClick = (key, childProps) => {
    this.props.setShowMarkerById(key);
    this.map.setCenter({ lat: childProps.lat, lng: childProps.lng });
  }

  parseReservationsToMarker = (reservations, isActive) => {
    const { busStops, reservationMarker } = this.props;
    var components = [];
    for (var i = 0; i < reservations.length; i++) {
      var reservation = reservations[i];
      var lat, lng;
      if (reservation.bus_stop) {
        lat = busStops[reservation.bus_stop].lat;
        lng = busStops[reservation.bus_stop].lng;
      } else {
        lat = reservation.address.lat;
        lng = reservation.address.lng;
      }
      var color;
      if (reservationMarker && reservationMarker.id === reservation.id) {
        color = "#0fab42";
      } else {
        color = isActive ? "#ff764b" : "gray";
      }
      components.push(
        <MarkerComponent
          key={reservation.id}
          lat={lat}
          lng={lng}
          text={reservation.order}
          fill={color}
        />
      );
    }
    return components;
  }

  renderMarkers = () => {
    var markers = [];
    const { activeReservations, endedReservations } = this.props;
    markers.push(this.parseReservationsToMarker(activeReservations, true));
    markers.push(this.parseReservationsToMarker(endedReservations, false));
    return markers;
  }

  apiIsLoaded = (map, maps) => {
    this.map = map;
    this.maps = maps;
    this.fitBoundsOfMap();
  }

  renderDriverMarker = () => {
    if (this.props.driverLocation == null) {
      return null;
    }
    const { lat, lng } = this.props.driverLocation;
    if (lat && lng) {
      return (
        <MarkerComponent
          lat={lat}
          lng={lng}
          text={"Ja"}
          fill={"#4593ea"}
        />
      )
    } else {
      return null;
    }
  }

  fitBoundsOfMap = () => {
    const { activeReservations: markers, busStops, reservationMarker } = this.props;
    if (reservationMarker) {
      return;
    }

    if (markers.length > 0) {
      if (markers.length === 1) {
        var place = markers[0];
        var lat, lng;
        if (place.bus_stop) {
          lat = busStops[place.bus_stop].lat;
          lng = busStops[place.bus_stop].lng;
        } else {
          lat = place.address.lat;
          lng = place.address.lng;
        }
        this.map.setCenter({ lat: lat, lng: lng });
        this.map.setZoom(13);
      } else {
        const bounds = new this.maps.LatLngBounds();
        markers.forEach((place) => {
          var lat, lng;
          if (place.bus_stop) {
            lat = busStops[place.bus_stop].lat;
            lng = busStops[place.bus_stop].lng;
          } else {
            lat = place.address.lat;
            lng = place.address.lng;
          }
          bounds.extend(new this.maps.LatLng(
            lat,
            lng,
          ));
        });
        this.map.fitBounds(bounds);
      }
    }
  }

  render() {
    const { reservationMarker, setShowMarkerById, busStops } = this.props;
    var centerCoords = {
      lat: 59.913020,
      lng: 10.752581
    };
    if (reservationMarker) {
      var lat, lng;
      if (reservationMarker.bus_stop) {
        lat = busStops[reservationMarker.bus_stop].lat;
        lng = busStops[reservationMarker.bus_stop].lng;
      } else {
        lat = reservationMarker.address.lat;
        lng = reservationMarker.address.lng;
      }
      centerCoords = {
        lat: lat,
        lng: lng
      };
    }

    return (
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <div style={{ height: '85vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBM28iY5Jv9N0a-aLbEZEZ9npAg44oxvBk' }}
            defaultCenter={centerCoords}
            defaultZoom={11}
            onChildClick={this.onChildClick}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
          >
            {this.renderMarkers()}
            {this.renderDriverMarker()}
          </GoogleMapReact>
        </div>
        <MarkerData
          setShowMarkerById={setShowMarkerById}
          reservationMarker={reservationMarker}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    completedReservations: state.app.completedReservations,
    busStops: state.app.busStops,
    driverLocation: state.app.driverLocation
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleRoute })(withStyles(styles)(RouteMap))
);
