import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { removeReservationFromChange } from 'reducers/appActions';
import { withRouter } from "react-router";
import VisibilitySensor from 'react-visibility-sensor';
import Badge from '@material-ui/core/Badge';

const styles = {
  customDot: {
    padding: '5px',
  }
};

class ChangeDetector extends React.Component {

  constructor(props) {
    super(props);
    this.timouter = null;
    this.state = { sensorActive: false };
  }

  componentDidMount() {
    if (this.checkIsAnyChangeForThisRoute() === true) {
      this.setState({ sensorActive: true });
    }
  }

  componentWillUnmount() {
    if (this.timouter) {
      window.clearTimeout(this.timouter);
    }
  }

  checkIsAnyChangeForThisRoute = () => {
    const { routeId, routesChanges, reservationId } = this.props;
    var changes = routesChanges[routeId];
    if (changes && changes.reservations && Object.keys(changes.reservations).length > 0) {
      var obj = changes.reservations[reservationId];
      return obj ? true : false;
    } else {
      return false;
    }
  }

  onChange = (isVisible) => {
    const { routeId, reservationId } = this.props;
    if (isVisible) {
      if (this.timouter === null) {
        this.timouter = window.setTimeout(
          () => { this.props.removeReservationFromChange(routeId, reservationId) },
          4500
        );
      }
    }
  }

  getChangedReservation = () => {
    const { routeId, reservationId, routesChanges, field } = this.props;
    var route = routesChanges[routeId];
    if (route) {
      if (route.reservations) {
        if (route.reservations[reservationId]) {
          if (Array.isArray(field)) {
            for (var i = 0; i < field.length; i++) {
              var key = field[i];
              if (route.reservations[reservationId][key]) {
                return true;
              }
            }
          } else {
            return route.reservations[reservationId][field];
          }
        }
      }
    }
    return null;
  }

  render() {
    var hideNotification = this.getChangedReservation() ? false : true;
    return (
      <VisibilitySensor
        active={this.state.sensorActive}
        onChange={this.onChange}
      >
        <Badge invisible={hideNotification} color="secondary" variant="dot">
          {this.props.children}
        </Badge>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routesChanges: state.app.routesChanges,
  };
};

export default withRouter(
  connect(mapStateToProps, { removeReservationFromChange })(withStyles(styles)(ChangeDetector))
);
