import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberCounter from 'components/common/NumberCounter';
import { PathMatcher } from 'PathMatcher';
import FormSelectField from 'components/common/FormSelectField';
import GoogleSearchPlaces from 'components/common/GoogleSearchPlaces';
import PlaceHelper from 'utils/PlaceHelper';

const styles = {
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  price: {
    fontSize: "17px",
    color: "green"
  },
  ft15: {
    fontSize: "15px",
    textAlign: "center"
  },
  gridActions: {
    padding: "20px",
    textAlign: "center"
  }
};

class SplitForm extends React.Component {

  convertInt = (value) => {
    var v = parseInt(value);
    if (Number.isInteger(v)) {
      return v;
    } else {
      return "";
    }
  }

  handleChangeRadio = (e) => {
    var value = e.target.value;
    this.props.updateParams('rideStatus', value);
  }

  handleChangePrice = (e) => {
    this.props.updateParams("newPrice", this.convertInt(e.target.value));
  }

  handleChangeCardPaymentAmount = (e) => {
    this.props.updateParams("cardPaymentAmount", this.convertInt(e.target.value));
  }

  changeCardPayment = (e) => {
    this.props.updateParams("cardPayment", e.target.checked);
  }

  changeSplitPayment = (e) => {
    this.props.updateParams("splitPayment", e.target.checked);
  }

  changeIsBusStop = (e) => {
    this.props.updateParams("isBusStop", e.target.checked);
  }

  handleChangeGooglePlace = (place) => {
    this.props.updateParams("selectedAddress", place);
  }

  handleChangeBusStop = (obj) => {
    this.props.updateParams("selectedBusStop", obj);
  }

  getErrors = () => {
    var errors = this.props.error;
    if (errors === null) {
      return null;
    } else {
      return errors.map((error, index) => {
        return (<p style={{ color: "red" }} key={index}>{error}</p>);
      })
    }
  }

  handleSaveClick = () => {
    this.props.saveAction();
  }

  handleCancelButton = () => {
    var routeId = this.props.match.params.routeId;
    var url = PathMatcher.routeShowPath({ id: routeId });
    this.props.history.push(url);
  }

  renderEditAddress = () => {
    const { busStopSelectData, params, prices } = this.props;
    if (params.isBusStop) {
      return (
        <div style={{ marginTop: '20px', width: "92%" }}>
          <FormSelectField
            meta={{}}
            input={{ value: params.selectedBusStop, onChange: this.handleChangeBusStop }}
            label="Przystanki"
            suggestions={busStopSelectData}
          />
        </div>
      );
    } else {
      var placeHelper = new PlaceHelper(params.selectedAddress, prices);
      return (
        <div style={{ width: "92%" }}>
          <GoogleSearchPlaces
            defaultValue={params.selectedAddress ? params.selectedAddress.name : null}
            error={placeHelper.getSingleError()}
            selectedPlace={this.handleChangeGooglePlace}
          />
        </div>
      );
    }
  }

  render() {
    const { classes, updateParams, params, isEdit, reservation
    } = this.props;
    var isBlockedForFlyo = reservation.is_flyo_app;
    return (
      <div style={{ marginTop: "-25px" }}>
        <Grid
          className={`${classes.rowItem} reservationItem`}
          container
          spacing={0}
          alignContent="center"
        >
          <Grid item xs={6}>
            {isBlockedForFlyo ? (
              <p className="blocked-comm">Edycja dla rezerwacji z Flyo jest zablokowana.</p>
            ) : (
              <Fragment>
                <NumberCounter
                  label={'Osoby dorosłe'}
                  value={params.adults}
                  onChange={(v) => updateParams('adults', v)}
                />
                <NumberCounter
                  label={'Dzieci'}
                  value={params.children}
                  onChange={(v) => updateParams('children', v)}
                />
                <NumberCounter
                  label={'Studenci'}
                  value={params.students}
                  onChange={(v) => updateParams('students', v)}
                />
                {isEdit && (
                  <Fragment>
                    <FormControlLabel
                      style={{ display: "block", textAlign: "center" }}
                      control={
                        <Checkbox
                          label="Przystanek"
                          checked={params.isBusStop}
                          onChange={this.changeIsBusStop}
                        />
                      }
                      label="Przystanek"
                    />
                    {this.renderEditAddress()}
                  </Fragment>
                )}
                <p className={classes.ft15}>Cena rezerwacji: {' '}
                  <span className={classes.price}>{params.price} NOK</span>
                </p>
              </Fragment>)}
          </Grid>

          <Grid item xs={6}>
            <RadioGroup
              aria-label="position"
              name="status"
              value={params.rideStatus}
              onChange={this.handleChangeRadio}
              row
            >
              <FormControlLabel
                value=""
                control={<Radio color="primary" />}
                label="Brak"
                labelPlacement="start"
              />
              <FormControlLabel
                value="noshow"
                control={<Radio color="primary" />}
                label="No show"
                labelPlacement="start"
              />
              <FormControlLabel
                value="gratis"
                control={<Radio color="primary" />}
                label="Gratis"
                labelPlacement="start"
              />
            </RadioGroup>
            <FormControlLabel
              control={
                <Checkbox
                  label="Zapłacono kartą"
                  checked={params.cardPayment}
                  onChange={this.changeCardPayment}
                />
              }
              label="Zapłacono kartą"
            />
            {isEdit && (
              <FormControlLabel
                control={
                  <Checkbox
                    label="Rozdzielona płatność"
                    checked={params.splitPayment}
                    onChange={this.changeSplitPayment}
                  />
                }
                label="Rozdzielona płatność"
              />
            )}

            {(isEdit && params.splitPayment) && (
              <TextField
                style={{ display: "inline-block" }}
                error={false}
                id="standard-number"
                label="Płatność kartą (NOK)"
                value={params.cardPaymentAmount}
                onChange={this.handleChangeCardPaymentAmount}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
            )}

            <TextField
              style={{ display: "inline-block" }}
              error={false}
              id="standard-number"
              label="Nowa cena rezerwacji"
              value={params.newPrice}
              onChange={this.handleChangePrice}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </Grid>

          <Grid className={classes.gridActions} item xs={12}>
            {this.getErrors()}
            <Button
              style={{ marginRight: "70px" }}
              disabled={this.props.isLoading}
              size="small"
              variant="contained"
              type="submit"
              onClick={this.handleCancelButton}
            >
              Anuluj
            </Button>
            <Button
              disabled={this.props.isLoading}
              size="small"
              variant="contained"
              type="submit"
              onClick={this.handleSaveClick}
            >
              {
                this.props.isLoading
                  ?
                  'Przetwarzam...'
                  :
                  'Zapisz'
              }
            </Button>
          </Grid>
        </Grid>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(SplitForm))
);
