import React from 'react';
import { connect } from 'react-redux';
import {
  createErrorNotification, createSuccessNotification
} from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import { PathMatcher } from 'PathMatcher';
import MainSplitReservation from "./MainSplitReservation";
import SplitForm from "./SplitForm";
import merge from 'lodash/merge';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';
import CalculatePriceHelper from 'utils/CalculatePriceHelper';
import BusStopsHelper from 'utils/BusStopsHelper';
import PlaceHelper from 'utils/PlaceHelper';

const styles = {};

class EditReservation extends React.Component {

  constructor(props) {
    super(props);
    var reservation = props.reservation;
    var rideStatus = "";
    if (reservation.no_show) {
      rideStatus = "noshow";
    } else if (reservation.gratis) {
      rideStatus = "gratis";
    }
    var address = null;
    var busStop = null;
    if (reservation.bus_stop) {
      busStop = {
        value: reservation.bus_stop,
        label: this.getNiceBusStopName(reservation.bus_stop)
      };
    } else {
      address = {
        address_components: [
          { types: ["street_number"], short_name: "1" },
          { types: ["postal_code"], short_name: reservation.address.post_code },
        ],
        geometry: {
          location: {
            lat: () => reservation.address.lat,
            lng: () => reservation.address.lng,
          }
        },
        name: reservation.address.name
      }
    }
    var splitAmount = reservation.splitted_payment_amount ? reservation.splitted_payment_amount : "";
    this.state = {
      reservationParams: {
        adults: reservation.adult_quantity,
        children: reservation.child_quantity,
        students: reservation.student_quantity,
        seniors: reservation.senior_quantity,
        teenagers: reservation.teenager_quantity,
        rideStatus: rideStatus,
        newPrice: "",
        cardPayment: reservation.card_payment_in_bus,
        price: reservation.price,
        splitPayment: reservation.splitted_payment_amount !== null,
        cardPaymentAmount: splitAmount,
        selectedAddress: address,
        isBusStop: reservation.bus_stop !== null,
        selectedBusStop: busStop
      },
      error: null,
      isLoading: false
    };
  }

  updateReservationParams = (name, value) => {
    var error = null;
    var reservationParams = merge({}, this.state.reservationParams);
    reservationParams[name] = value;
    error = this.getError(reservationParams);
    reservationParams.price = this.getNewPrice(reservationParams);
    this.setState({ reservationParams: reservationParams, error: error });
  }

  getNiceBusStopName(value) {
    const { busStops } = this.props;
    if (busStops) {
      if (busStops[value]) {
        return busStops[value].name;
      }
    }
    return value;
  }

  getNewPrice = (reservationParams) => {
    const { prices } = this.props;
    var busStop = null;
    if (reservationParams.isBusStop && reservationParams.selectedBusStop) {
      busStop = reservationParams.selectedBusStop.value;
    }
    var obj = {
      adults: reservationParams.adults,
      children: reservationParams.children,
      students: reservationParams.students,
      bus_stop: busStop,
      postcode: null
    };

    var helper = new PlaceHelper(reservationParams.selectedAddress, prices);
    if (helper.isValid(true)) {
      obj.postcode = helper.getPostcode();
    }
    var newPrice = CalculatePriceHelper.calculatePrice(obj, prices);
    return newPrice;
  }

  getError = (reservationParams) => {
    const { prices } = this.props;
    var cond1 = reservationParams.adults < 0 || reservationParams.children < 0;
    var cond2 = reservationParams.adults === 0 && reservationParams.children === 0;
    var errors = [];
    if (cond1 || cond2) {
      errors.push("Niepoprawna liczba pasażerów.");
    }
    if (Number.isInteger(reservationParams.newPrice)) {
      if (reservationParams.newPrice <= 0) {
        errors.push("Niepoprawna nowa cena rezerwacji.");
      }
    }

    if (reservationParams.splitPayment) {
      if (reservationParams.cardPaymentAmount <= 0) {
        errors.push("Niepoprawna kwota - płaność kartą.");
      }
    }

    if (reservationParams.isBusStop === true) {
      if (reservationParams.selectedBusStop === null) {
        errors.push('Wybierz przystanek');
      }
    } else {
      var helper = new PlaceHelper(reservationParams.selectedAddress, prices);
      if (!helper.isValid(true)) {
        errors.push('Niepoprawny adres');
      }
    }

    if (errors.length === 0) {
      return null;
    } else {
      return errors;
    }
  }

  updateReservation = () => {
    const { prices } = this.props;
    //cancel if is any error
    var error = this.getError(this.state.reservationParams);
    if (error !== null) {
      this.setState({ error });
      return;
    }
    this.setState({ isLoading: true });
    var reParams = this.state.reservationParams;

    var params = {
      originReservationId: parseInt(this.props.match.params.reservationId),
      adults: reParams.adults,
      teenagers: reParams.teenagers,
      seniors: reParams.seniors,
      students: reParams.students,
      children: reParams.children,
      noShow: reParams.rideStatus === "noshow",
      gratis: reParams.rideStatus === "gratis",
      cardPayment: reParams.cardPayment,
      newPrice: reParams.newPrice,
      price: reParams.price,
      splitPayment: reParams.splitPayment,
      cardPaymentAmount: reParams.cardPaymentAmount,
      bus_stop: null
    };

    if (reParams.isBusStop === true) {
      params.bus_stop = reParams.selectedBusStop.value;
    } else {
      var helper = new PlaceHelper(reParams.selectedAddress, prices);
      params.address = helper.getAddressName();
      const { lat, lng } = helper.getCoordinates();
      params.lat = lat;
      params.lng = lng;
      params.postcode = helper.getPostcode();
    }

    axios.post('/update-reservation', RequestHelper.signParams(params))
      .then(response => {
        this.props.createSuccessNotification({
          message: "Rezerwacja została zaktualizowana."
        });
        this.setState({ isLoading: false });
        var routeId = this.props.match.params.routeId;
        var url = PathMatcher.routeShowPath({ id: routeId });
        this.props.history.push(url);
      })
      .catch(error => {
        console.log("ERRR", error)
        var isUnauthorized = RequestHelper.isUnauthorized(error);
        if (isUnauthorized) {
          this.props.history.push('/login');
        } else if (!RequestHelper.isConnection(error)) {
          alert("No connection!");
        } else {
          this.props.createErrorNotification({
            message: error.response.data.common
          });
        }
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { reservation, prices } = this.props;
    var busStopSelectData = BusStopsHelper.parseDataToReactSelect(this.props.busStops);
    return (
      <div style={{ padding: "10px" }}>
        <MainSplitReservation
          reservation={reservation}
        />
        <SplitForm
          busStopSelectData={busStopSelectData}
          prices={prices}
          reservation={reservation}
          updateParams={this.updateReservationParams}
          params={this.state.reservationParams}
          error={this.state.error}
          saveAction={this.updateReservation}
          isLoading={this.state.isLoading}
          isEdit
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busStops: state.app.busStops,
    routes: state.app.routes,
    prices: state.app.prices
  };
};

const mapDispatchToProps = {
  createErrorNotification,
  createSuccessNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditReservation))
);
