import React from 'react';
import { connect } from 'react-redux';
import {
  createErrorNotification, createSuccessNotification
} from 'reducers/appActions';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import { PathMatcher } from 'PathMatcher';
import MainSplitReservation from "./MainSplitReservation";
import SplitForm from "./SplitForm";
import merge from 'lodash/merge';
import axios from 'AppRequest';
import RequestHelper from 'utils/RequestHelper';
import CalculatePriceHelper from 'utils/CalculatePriceHelper';

const styles = {};

class SplitReservation extends React.Component {

  state = {
    splitParams: {
      adults: 0,
      children: 0,
      students: 0,
      seniors: 0,
      teenagers: 0,
      rideStatus: "",
      newPrice: null,
      cardPayment: false,
      price: 0,
    },
    error: null,
    isLoading: false
  };

  updateSplitParams = (name, value) => {
    var error = null;
    var splitParams = merge({}, this.state.splitParams);
    splitParams[name] = value;
    error = this.getError(splitParams);
    splitParams.price = this.getNewPrice(splitParams);
    this.setState({ splitParams: splitParams, error: error });
  }

  getNewPrice = (splitParams) => {
    const { prices, reservation } = this.props;
    var pc = reservation.address ? reservation.address.post_code : null;
    var obj = {
      adults: splitParams.adults,
      children: splitParams.children,
      students: splitParams.students,
      bus_stop: reservation.bus_stop,
      postcode: pc
    };
    var newPrice = CalculatePriceHelper.calculatePrice(obj, prices);
    return newPrice;
  }

  getError = (splitParams) => {
    var cond1 = splitParams.adults < 0 || splitParams.children < 0;
    var cond2 = splitParams.adults === 0 && splitParams.children === 0;
    var errors = [];
    if (cond1 || cond2) {
      errors.push("Niepoprawna liczba pasażerów.");
    }
    if (Number.isInteger(splitParams.newPrice)) {
      if (splitParams.newPrice <= 0) {
        errors.push("Niepoprawna nowa cena rezerwacji.");
      }
    }

    var originReservation = this.props.reservation;
    var originAdults = originReservation.adult_quantity;
    var originChildren = originReservation.child_quantity;
    if ((splitParams.adults > originAdults || splitParams.children > originChildren) ||
      splitParams.adults === originAdults && splitParams.children === originChildren) {
      errors.push("Za duża liczba nowych pasażerów.");
    }

    if (errors.length === 0) {
      return null;
    } else {
      return errors;
    }
  }

  splitReservation = () => {
    //cancel if is any error
    var error = this.getError(this.state.splitParams);
    if (error !== null) {
      this.setState({ error });
      return;
    }
    this.setState({ isLoading: true });
    var splitParams = this.state.splitParams;
    var params = {
      originReservationId: parseInt(this.props.match.params.reservationId),
      adults: splitParams.adults,
      children: splitParams.children,
      noShow: splitParams.rideStatus === "noshow",
      gratis: splitParams.rideStatus === "gratis",
      cardPayment: splitParams.cardPayment,
      newPrice: splitParams.newPrice,
      price: splitParams.price
    };

    axios.post('/split-reservation', RequestHelper.signParams(params))
      .then(response => {
        this.props.createSuccessNotification({
          message: "Rezerwacja została stworzona."
        });
        this.setState({ isLoading: false });
        var routeId = this.props.match.params.routeId;
        var url = PathMatcher.routeShowPath({ id: routeId });
        this.props.history.push(url);
      })
      .catch(error => {
        console.log("ERRR", error)
        var isUnauthorized = RequestHelper.isUnauthorized(error);
        if (isUnauthorized) {
          this.props.history.push('/login');
        } else if (!RequestHelper.isConnection(error)) {
          alert("No connection!");
        } else {
          this.props.createErrorNotification({
            message: error.response.data.common
          });
        }
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { reservation } = this.props;
    return (
      <div style={{ padding: "10px" }}>
        <MainSplitReservation
          reservation={reservation}
          splitParams={this.state.splitParams}
        />
        <SplitForm
          reservation={reservation}
          updateParams={this.updateSplitParams}
          params={this.state.splitParams}
          error={this.state.error}
          saveAction={this.splitReservation}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    prices: state.app.prices
  };
};

const mapDispatchToProps = {
  createErrorNotification,
  createSuccessNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SplitReservation))
);
