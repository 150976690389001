import React from 'react';
import Layout from 'components/common/Layout';
import { connect } from 'react-redux';
import {
  fetchRoutes, checkRoute, fetchBusStops, removeRouteFromChange,
  fetchPrices, createErrorNotification
} from 'reducers/appActions';
import ReservationItem from './ReservationItem';
import includes from 'lodash/includes';
import { withStyles } from '@material-ui/styles';
import { toggleRoute } from 'reducers/appActions';
import { withRouter } from "react-router";
import find from 'lodash/find';
import Button from '@material-ui/core/Button';
import RouteList from './RouteList';
import RouteMap from './RouteMap';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ListIcon from '@material-ui/icons/List';
import MapIcon from '@material-ui/icons/Map';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import RouteAction from './RouteAction';
import FlightBar from 'components/common/FlightBar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { PathMatcher } from 'PathMatcher';
import ReservationHelper from 'utils/ReservationHelper';
import classnames from 'classnames';
import LocalStorageHelper from 'utils/LocalStorageHelper';
import MergeLiftsWithReservations from 'utils/MergeLiftsWithReservations';

const styles = {
  white: {
    color: 'white'
  },
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  activeAppBarButton: {
    backgroundColor: "#1968bf!important",
    color: "white!important"
  },
  mLeft: {
    marginLeft: "10px"
  },
  contained: {
    marginLeft: "15px",
    backgroundColor: "#4593ea",
    color: "white",
    "&:hover": {
      backgroundColor: "#1968bf",
    }
  },
  actionToolbar: {
    backgroundColor: "#6eb2ff"
  },
  rightIcon: {
    marginLeft: "10px",
  },
  margin0: { margin: "0" }

};

class RoutePage extends React.Component {

  state = { listView: true, reservationMarker: null };

  componentDidMount() {
    var route = this.getRoute();
    if (!route) {
      var url = PathMatcher.homepagePath();
      this.props.history.push(url);
    } else {
      this.props.checkRoute(this.props.match.params.id);
      this.props.fetchBusStops();
      this.props.fetchRoutes();
      this.props.fetchPrices();
      this.props.removeRouteFromChange(this.props.match.params.id);
    }
  }

  refreshAction = () => {
    this.props.fetchBusStops();
    this.props.fetchRoutes();
    this.props.checkRoute(this.props.match.params.id);
  }

  reservationsItemsData = (completedFilter) => {
    const { routes, completedReservations } = this.props;
    if (routes.length === 0) {
      return { items: null, size: 0 };
    }

    var route = this.getRoute();
    var reservations = new MergeLiftsWithReservations(route).merge();
    var components = [];
    var plainReservations = [];
    for (var i = 0; i < reservations.length; i++) {
      var reservation = reservations[i];
      var isCompleted = includes(completedReservations, reservation.id);
      if (completedFilter) {
        if (isCompleted) {
          plainReservations.push(reservation);
          components.push(
            <ReservationItem
              showMarkerOnMap={this.showMarkerOnMap}
              data={reservation}
              key={reservation.id}
            />
          );
        }
      } else {
        if (!isCompleted) {
          plainReservations.push(reservation);
          components.push(
            <ReservationItem
              showMarkerOnMap={this.showMarkerOnMap}
              data={reservation}
              key={reservation.id}
            />
          );
        }
      }
    }
    return {
      items: components,
      size: components.length,
      reservations: plainReservations
    };
  }

  changeViewTypeToList = () => {
    this.setState({ listView: true });
  }

  changeViewTypeToMap = () => {
    if (this.state.listView === true) {
      this.setState({ listView: false, reservationMarker: null });
    }
  }

  showMarkerOnMap = (reservation) => {
    this.setState({ listView: false, reservationMarker: reservation });
  }

  getRoute = () => {
    const { match, routes } = this.props;
    var routeId = match.params.id;
    return find(routes, function (o) { return o.id === parseInt(routeId); });
  }

  getRouteBus = () => {
    var route = this.getRoute();
    return route.route_buses[0];
  }

  setShowMarkerById = (reservationId) => {
    if (reservationId === null) {
      this.setState({ reservationMarker: null });
    } else {
      reservationId = parseInt(reservationId);
      var route = this.getRoute();
      var reservations = new MergeLiftsWithReservations(route).merge();
      var reservation = find(reservations, function (o) { return o.id === reservationId; });
      this.setState({ reservationMarker: reservation });
    }
  }

  handleClickAttendanceList = () => {
    var routeId = this.props.match.params.id;
    var url = PathMatcher.attendancePath({ routeId: routeId });
    this.props.history.push(url);
  }

  handleClickDownloadPdf = () => {
    var id = this.props.match.params.id;
    var busId = this.getBus().id;
    var salt = LocalStorageHelper.getSalt();
    var url = `${process.env.REACT_APP_PANEL_URL}/api-v2/pdfs-download/${busId}/${id}/${salt}`;
    var win = window.open(url, '_blank');
    win.focus();
  }

  appBarElements = () => {
    if (this.props.routes.length === 0) {
      return null;
    }
    var route = this.getRoute();
    var countTotalPeople = ReservationHelper.countTotalPeopleInRoute(route);
    var routeBus = this.getRouteBus();
    return (
      <div className="inline" style={{ position: "relative" }}>
        <FlightBar
          settlementStatus={route.route_buses[0].settlement_status}
          flightDirection={route.flight_direction}
          flights={route.flights}
          isLift={routeBus.is_lift}
        />
        <p style={{ margin: "0", padding: "0", fontSize: "12px" }}>
          Miejsca: {countTotalPeople}/{routeBus.bus.passenger}
        </p>
      </div>
    );
  }

  getBus = () => {
    var id = this.props.match.params.id;
    var route = find(this.props.routes, function (o) { return o.id === parseInt(id); });
    return route.route_buses[0].bus;
  }

  handleClickAddReservation = (e) => {
    e.preventDefault();
    var routeId = this.props.match.params.id;
    var route = this.getRoute();
    var routeBusId = route.route_buses[0].id;
    var url = PathMatcher.reservationCreatePath({ routeId: routeId, routeBusId: routeBusId });
    this.props.history.push(url);
  }

  goToSettlement = (e) => {
    e.preventDefault();
    var routeId = this.props.match.params.id;
    const { createErrorNotification, routeData } = this.props;
    var isRouteEnded = false;
    if (routeData && routeData.status === 3) {
      isRouteEnded = true;
    }
    if (isRouteEnded) {
      var url = PathMatcher.settlementPath({ routeId: routeId });
      this.props.history.push(url);
    } else {
      createErrorNotification({
        message: "Trasa musi zostać zakończona, aby można było przejść do rozliczenia."
      })
    }
  }

  getNiceBusStopName(value) {
    const { busStops } = this.props;
    if (busStops) {
      if (busStops[value]) {
        return busStops[value].name;
      }
    }
    return value;
  }

  getMainLiftsInfo() {
    var routeBus = this.getRouteBus();
    // is main bus with lifts
    var results = [];
    if (routeBus.lifts.length > 0) {
      for (var key in routeBus.lifts) {
        var lift = routeBus.lifts[key];
        var temp = {};
        temp.text = `Podwóz ${lift.driver.name},
        ${this.getNiceBusStopName(lift.bus_stop_lift_meet)}, ${lift.bus_stop_lift_comment}`;
        temp.driver = lift.driver;
        results.push(temp);
      }
    } else if (routeBus.parent_route_bus) {
      var prb = routeBus.parent_route_bus;
      var temp = {};
      temp.text = `Podwóz ${prb.driver.name},
      ${this.getNiceBusStopName(routeBus.bus_stop_lift_meet)}, ${routeBus.bus_stop_lift_comment}`;
      temp.driver = prb.driver;
      results.push(temp);
    }
    return results;
  }

  render() {
    var route = this.getRoute();
    if (!route) {
      return (
        <p>Nie znaleziono trasy.
        Przejdź do <a href="">listy tras</a>
        </p>
      );
    }

    const { fetchingRoutes, routeDataLoading, sendingRequestReservation,
      fetchingBusStops, classes } = this.props;
    const { listView, reservationMarker } = this.state;
    const {
      size: activeReservationsSize,
      items: activeReservations,
      reservations: activePlainReservations
    } = this.reservationsItemsData(false);
    const {
      size: endedReservationsSize,
      items: endedReservations,
      reservations: endedPlainReservations
    } = this.reservationsItemsData(true);
    var isLoading = fetchingRoutes || routeDataLoading ||
      fetchingBusStops || sendingRequestReservation;
    var activeListClass = this.state.listView ? classes.activeAppBarButton : '';
    var activeMapClass = !this.state.listView ? classes.activeAppBarButton : '';
    var liftsTabData = this.getMainLiftsInfo();

    return (
      <Layout
        label="Rezerwacje"
        isLoading={isLoading}
        refresh={this.refreshAction}
        elements={this.appBarElements()}
        backUrl={PathMatcher.homepagePath()}
      >
        <AppBar className={classes.actionToolbar} position="relative">
          <Toolbar style={{ margin: "0 auto" }}>
            <Button
              className={classnames(classes.contained, classes.margin0)}
              size="small"
              variant="contained"
              type="submit"
              onClick={this.handleClickDownloadPdf}
            >
              <PdfIcon size={22} />
            </Button>

            <Button
              className={classes.contained}
              size="small"
              variant="contained"
              onClick={this.handleClickAttendanceList}
            >
              <PeopleIcon size={22} />
            </Button>

            <Button
              className={classes.contained}
              size="small"
              variant="contained"
              onClick={this.goToSettlement}
            >
              <AttachMoneyIcon size={22} />
            </Button>
            <Button
              className={`${classes.contained} ${activeListClass}`}
              size="small"
              variant="contained"
              onClick={this.changeViewTypeToList}
            >
              <ListIcon size={22} />
            </Button>
            <Button
              className={`${classes.contained} ${activeMapClass}`}
              size="small"
              variant="contained"
              onClick={this.changeViewTypeToMap}
            >
              <MapIcon size={22} />
            </Button>
            <RouteAction routeId={this.props.match.params.id} />
            <Fab
              size="small"
              color="secondary"
              aria-label="add"
              onClick={this.handleClickAddReservation}
              style={{ backgroundColor: "#1968bf", marginLeft: "15px" }}
            >
              <AddIcon />
            </Fab>
          </Toolbar>
        </AppBar>
        {
          listView
            ?
            <RouteList
              activeReservationsSize={activeReservationsSize}
              activeReservations={activeReservations}
              endedReservationsSize={endedReservationsSize}
              endedReservations={endedReservations}
              liftsTabData={liftsTabData}
            />
            :
            <RouteMap
              activeReservations={activePlainReservations}
              endedReservations={endedPlainReservations}
              reservationMarker={reservationMarker}
              setShowMarkerById={this.setShowMarkerById}
            />
        }

      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busStops: state.app.busStops,
    routes: state.app.routes,
    fetchingRoutes: state.app.fetchingRoutes,
    routeDataLoading: state.app.routeDataLoading,
    fetchingBusStops: state.app.fetchingBusStops,
    completedReservations: state.app.completedReservations,
    sendingRequestReservation: state.app.sendingRequestReservation,
    routeData: state.app.routeData
  };
};

const mapDispatchToProps = {
  removeRouteFromChange,
  toggleRoute,
  fetchRoutes,
  checkRoute,
  fetchBusStops,
  fetchPrices,
  createErrorNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoutePage))
);
