import React from 'react';
import Form from './Form';
import axios from 'AppRequest';
import { withRouter } from "react-router";
import LocalStorageHelper from 'utils/LocalStorageHelper';
import { SubmissionError } from 'redux-form';

class LoginPage extends React.Component {

  submit = (values) => {
    var params = {
      data: {
        login: values.login,
        password: values.password,
        device: "",
      }
    }

    return axios.post('/login', params)
      .then(response => {
        LocalStorageHelper.saveUid(response.data.userId);
        LocalStorageHelper.saveSalt(response.data.salt);
        this.props.history.push('/');
      })
      .catch(error => {
        throw new SubmissionError({
          login: 'Niepoprawne dane.',
          password: ' '
        });
      })
  }

  render() {
    return (
      <div className="login-container">
        <section className="login" id="login">
          <header>
            <h3>PKS Driver</h3>
          </header>
          <p>Zaloguj się do aplikacji. Do poprawnego działana wymagane są uprawnienia do powiadomień i lokalizacji.</p>
          <Form onSubmit={this.submit} />
        </section>
      </div>
    );
  }
}

export default withRouter(LoginPage);
