import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import ReservationHelper from 'utils/ReservationHelper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const styles = {
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  kids: {
    color: "#4593ea"
  },
  price: {
    fontSize: "17px",
    color: "green"
  },
  ft15: {
    fontSize: "15px"
  },
  ft13: {
    fontSize: "13px"
  },
  ft13bold: {
    fontSize: "13px",
    fontWeight: "bold"
  },
  ft13blue: {
    fontSize: "12px",
    color: "#1968bf"
  },
  block: {
    display: "block"
  }
};

class MainSplitReservation extends React.Component {

  render() {
    const { classes, busStops, reservation, splitParams } = this.props;
    var rs = new ReservationHelper(reservation, busStops);
    if (splitParams) {
      rs.setSplitParams(splitParams);
    }
    return (
      <Grid
        className={`${classes.rowItem} reservationItem`}
        container
        spacing={0}
        alignContent="center"
      >
        <Grid item xs={2}>
          {rs.isSetBusArrival() && (
            <Typography className={classes.ft15} variant="subtitle1" gutterBottom>
              {rs.formattedBusArrival()}
            </Typography>
          )}
          <Typography
            className={classes.ft13}
            variant="subtitle1"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            <p
              style={{ margin: "0", position: "relative" }}
              dangerouslySetInnerHTML={{ __html: rs.destination() }}
            />
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className={classes.ft13bold} variant="subtitle1" gutterBottom>
            {rs.customerName()}
          </Typography>
          <Typography className={classes.ft13} variant="subtitle1" gutterBottom>
            {rs.pickupName()}
          </Typography>
          <Typography className={classes.ft13} variant="subtitle1">
            {rs.postcode()}
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Typography className={classes.kids} align="center" variant="subtitle2" gutterBottom>
            {(rs.adultQuantity() > 0) && (
              <Fragment> Dorośli: {rs.adultQuantity()}
                <br />
              </Fragment>
            )}
            {(rs.childQuantity() > 0) && (
              <Fragment> Dzieci: {rs.childQuantity()}
                <br />
              </Fragment>
            )}
            {(rs.studentQuantity() > 0) && (
              <Fragment> Studenci: {rs.studentQuantity()}
                <br />
              </Fragment>
            )}
            {(rs.teenagerQuantity() > 0) && (
              <Fragment> Nastolatki: {rs.teenagerQuantity()}
                <br />
              </Fragment>
            )}
            {(rs.seniorQuantity() > 0) && (
              <Fragment> Seniorzy: {rs.seniorQuantity()}
                <br />
              </Fragment>
            )}
          </Typography>
        </Grid>

        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Typography className={classes.ft13} variant="body2">
            {rs.additionalInformation()}
          </Typography>

          <Typography className={classes.ft13blue} variant="body2">
            {rs.liftInfo()}
          </Typography>

          {rs.isNoShow() && (
            <Typography className={classes.block} align="center" variant="caption">
              NO SHOW
            </Typography>
          )}
          {rs.isGratis() && (
            <Typography className={classes.block} align="center" variant="caption">
              GRATIS
            </Typography>
          )}
        </Grid>

        <Grid item xs={2} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Typography className={classes.price} align="center" variant="h6">
            {rs.priceText()} NOK
            {rs.isPaid() && (
              <Tooltip enterTouchDelay={0} title={"Reserwacja opłacona"}>
                <MoneyIcon className="is-paid" />
              </Tooltip>
            )}
            {rs.isPaidUsingCard() && (
              <Tooltip enterTouchDelay={0} title={"Zapłacone kartą"}>
                <CreditCardIcon className="is-paid" />
              </Tooltip>
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busStops: state.app.busStops
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(MainSplitReservation))
);
