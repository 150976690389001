import md5 from 'md5';
import moment from 'moment-timezone';
import merge from 'lodash/merge';
import LocalStorageHelper from 'utils/LocalStorageHelper';
import axios from 'AppRequest';

class RequestHelper {

  static signParams(params) {
    var userSalt = LocalStorageHelper.getSalt();
    var userId = LocalStorageHelper.getUid();
    var dateString = moment().tz('Europe/Warsaw').format("YYYY-MM-DDTHH:mm:ss");
    var mergedParams = merge({}, params, { date: dateString, userId: userId });
    var hash = md5(JSON.stringify(mergedParams) + userSalt);
    return {
      data: JSON.stringify(mergedParams),
      hash: hash,
    }
  }

  static isUnauthorized(error) {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        axios.post('/logout', RequestHelper.signParams({}));
        LocalStorageHelper.removeSalt();
        LocalStorageHelper.removeUid();
        return true;
      }
    }
    return false;
  }

  static isConnection(error){
    return error.response ? true : false;
  }
}


export default RequestHelper;
