class CalculatePriceHelper {
  static calculatePrice(reservationObject, prices) {
    var priceData;
    if (reservationObject.bus_stop) {
      priceData = this.getDataForBusStop(reservationObject.bus_stop, prices);
    } else {
      priceData = this.getDataForPostcode(reservationObject.postcode, prices);
    }
    if (!priceData) {
      return null;
    }
    var price = parseInt(reservationObject.adults) * priceData.adults;
    price += parseInt(reservationObject.children) * priceData.kids;
    price += parseInt(reservationObject.students) * priceData.students;
    return price;
  }

  static isValidPostcode(postcode, prices) {
    var data = this.getDataForPostcode(postcode, prices);
    return data ? true : false;
  }

  static getDataForBusStop(busStopKey, prices) {
    return prices.bus_stops.TRF[busStopKey];
  }

  static getDataForPostcode(postcode, prices) {
    return prices.TRF[postcode];
  }

}
export default CalculatePriceHelper;
