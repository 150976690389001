import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { toggleReservation } from 'reducers/appActions';
import { withRouter } from "react-router";
import Checkbox from '@material-ui/core/Checkbox';
import icNavigate from 'components/assets/icons/ic_navigate.png';
import icPhone from 'components/assets/icons/ic_phone.png';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Tooltip from '@material-ui/core/Tooltip';
import ReservationHelper from 'utils/ReservationHelper';

const styles = {
  rowItem: {
    marginBottom: '20px',
    borderBottom: '1px solid #eaeaea'
  },
  price: {
    color: "green"
  },
  kids: {
    color: "#4593ea"
  },
  ft18: {
    fontSize: "18px"
  },
  ft16: {
    fontSize: "16px"
  },
  ft12: {
    fontSize: "12px"
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: "5px",
    color: "red"
  },
  ft13blue: {
    fontSize: "12px",
    color: "#1968bf"
  },
};

class MarkerData extends React.Component {

  closeShowMarker = () => {
    this.props.setShowMarkerById(null);
  }

  handleClickCheckbox = () => {
    this.props.toggleReservation(this.props.reservationMarker.id);
  }

  openMap = (e, lat, lng) => {
    e.preventDefault();
    window.open(`https://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`);
  }

  render() {
    const { classes, reservationMarker, busStops, completedReservations } = this.props;
    if (!reservationMarker) {
      return null;
    }
    var rs = new ReservationHelper(reservationMarker, busStops);
    const { lat, lng } = rs.coordinates();

    return (
      <div className="reservation-map-item">
        <Typography align="center">
          <Checkbox
            style={{ padding: "5px" }}
            checked={rs.isCompletedReservation(completedReservations)}
            onChange={this.handleClickCheckbox}
          /> Odebrano
        </Typography>

        <IconButton
          edge="start"
          className={`${classes.menuButton} ${classes.closeButton}`}
          color="inherit"
          aria-label="open drawer"
          onClick={this.closeShowMarker}
        >
          <CloseIcon />
        </IconButton>

        <Typography className={classes.ft18} align="center" variant="h6" gutterBottom>
          {rs.isSetBusArrival() && (
            rs.formattedBusArrival()
          )}
        </Typography>

        <Typography className={classes.ft12} align="center" variant="h6" gutterBottom>
          <p
            style={{ margin: "0", position: "relative" }}
            dangerouslySetInnerHTML={{ __html: rs.destination() }}
          />
        </Typography>

        <Typography className={classes.ft18} align="center" variant="h6" gutterBottom>
          {rs.isSetOrder() && (
            <span>{rs.order()}.{' '}</span>
          )}
          {rs.customerName()}
        </Typography>

        <Typography className={classes.ft16} align="center" variant="body2" gutterBottom>
          {rs.pickupName()}
        </Typography>

        {rs.isSetPostCode() && (
          <Typography align="center" variant="body2" gutterBottom>
            kod: {rs.postcode()}
          </Typography>
        )}

        <hr className="cHr" />

        <Typography className={classes.kids} align="center" variant="subtitle2" gutterBottom>
          Osób: {rs.adultQuantity()} {' | '} Dzieci: {rs.childQuantity()}
        </Typography>

        <Typography align="center" variant="subtitle2" gutterBottom>
          {rs.additionalInformation()}
        </Typography>

        <Typography align="center" className={classes.ft13blue} variant="body2">
          {rs.liftInfo()}
        </Typography>

        <Typography className={classes.price} align="center" variant="h5" gutterBottom>
          {rs.priceText()} NOK
          {rs.isPaid() && (
            <Tooltip enterTouchDelay={0} title={"Reserwacja opłacona"}>
              <MoneyIcon className="is-paid" />
            </Tooltip>
          )}
        </Typography>

        <Typography align="center">
          {
            rs.isPhoneDefined()
              ?
              <a href={`tel:${rs.customerPhone()}`}>
                <img className="w45" src={icPhone} alt="Zadzwoń" />
              </a>
              :
              null
          }
          <a href="*" onClick={(e) => this.openMap(e, lat, lng)} className="mLeft15">
            <img className="w45" src={icNavigate} alt="Nawiguj" />
          </a>
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    completedReservations: state.app.completedReservations,
    busStops: state.app.busStops
  };
};

export default withRouter(
  connect(mapStateToProps, { toggleReservation })(withStyles(styles)(MarkerData))
);
