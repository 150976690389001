import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

class PassengersHelper {

  static parseData(attedanceList, filterId) {
    var passengers = (attedanceList && attedanceList.passengers) ? attedanceList.passengers : [];
    var results = [];
    var order = 0;
    var driverOrder = {};
    for (var i = 0; i < passengers.length; i++) {
      var p = passengers[i];
      if (filterId > 0 && p.driver_id !== filterId) {
        continue;
      }
      if (!driverOrder[p.driver_name]) {
        driverOrder[p.driver_name] = [];
      }
      driverOrder[p.driver_name].push(p);
    }

    for (var key in driverOrder) {
      var order = 0;
      var sortedPassengers = sortBy(driverOrder[key], [function (o) { return o.order; }]);
      for (var i = 0; i < sortedPassengers.length; i++) {
        var passenger = sortedPassengers[i];
        if (i === 0) {
          order++;
        } else {
          var sameCoords = sortedPassengers[i - 1].lat === passenger.lat &&
            sortedPassengers[i - 1].lng === passenger.lng;
          if (!sameCoords) {
            order++;
          }
        }
        var flightDate = moment(passenger.flight_date).format("HH:mm");
        passenger.flight = flightDate + " " + passenger.flight_city;
        passenger.order = order;
        results.push(passenger);
      }
    }
    return results;
  }

  static invertColor(hex) {
    const padZero = (str, len) => {
      len = len || 2;
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    }

    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
  }
}

export default PassengersHelper;
