import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { startRoute, endRoute } from 'reducers/appActions';
import { withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import * as C from 'utils/constants';
import find from 'lodash/find';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  button: {
    backgroundColor: "#029834",
    color: "white",
    padding: "5px 8px"
  },
  buttonRed: {
    backgroundColor: "#df1515",
    color: "white",
    padding: "5px 8px"
  },
  buttonOrange: {
    backgroundColor: "#dca237",
    color: "white",
    padding: "5px 8px"
  },
  fontSize15: {
    fontSize: "15px"
  }
};

class RouteAction extends React.Component {

  state = {
    isOpenStart: false,
    isOpenEnd: false,
    arrivePassengers: '',
    error: null
  };

  startRouteAction = () => {
    if (this.isArriveRoute()) {
      var count = parseInt(this.state.arrivePassengers);
      if (Number.isInteger(count) && count > 0) {
        this.props.startRoute({ routeId: this.props.routeId, passengersCount: count });
        this.handleClose();
      } else {
        this.setState({ error: "Niepoprawna liczba pasażerów." });
      }
    } else {
      this.props.startRoute({ routeId: this.props.routeId });
      this.handleClose();
    }
  }

  endRouteAction = () => {
    this.props.endRoute(this.props.routeId);
    this.handleClose();
  }

  handleClose = () => {
    this.setState({ isOpenStart: false, isOpenEnd: false, error: null });
  }

  handleShowStart = () => {
    this.setState({ isOpenStart: true, error: null });
  }

  handleShowEnd = () => {
    this.setState({ isOpenEnd: true, error: null });
  }

  isArriveRoute = () => {
    var id = this.props.match.params.id;
    var route = find(this.props.routes, function (o) { return o.id === parseInt(id); });
    return route.flight_direction === "arrive";
  }

  changeArrivePassengers = (e) => {
    this.setState({ arrivePassengers: e.target.value });
  }

  render() {
    const { classes, routeData, routeDataLoading } = this.props;
    if (routeData === null || routeDataLoading) {
      return (
        <div style={{ marginLeft: "15px" }}>
          <Button disabled variant="contained" className={classes.button}>
            ...
          </Button>
        </div>
      );
    }

    return (
      <div style={{ marginLeft: "15px" }}>
        {routeData.status === C.NOT_STARTED_ROUTE && (
          <Button
            size="small"
            onClick={this.handleShowStart}
            variant="contained"
            className={classes.button}
          >
            Rozpocznij
          </Button>
        )}
        {routeData.status === C.STARTED_ROUTE && (
          <Button
            size="small"
            onClick={this.handleShowEnd}
            variant="contained"
            className={classes.buttonRed}
          >
            Zakończ
          </Button>
        )}
        {routeData.status === C.ANOTHER_ROUTE_STARTED && (
          <Button
            disabled
            size="small"
            variant="contained"
            className={classes.buttonOrange}
          >
            Inna w trakcie
          </Button>
        )}
        {routeData.status === C.ENDED_ROUTE && (
          <Button
            disabled
            size="small"
            variant="contained"
            className={classes.buttonOrange}
          >
            Zakończono
          </Button>
        )}
        <Dialog
          open={this.state.isOpenStart}
          onClose={this.handleClose}
          className="dialog-reservation-actions"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            className={classes.fontSize15}
            id="form-dialog-title"
          >
            Czy na pewno rozpocząć trasę?
          </DialogTitle>
          <DialogContent>
            {this.isArriveRoute() && (
              <p style={{margin: "0"}}>Podaj liczbę pasażerów.</p>
            )}
            {this.isArriveRoute() && (
              <TextField
                error={this.state.error !== null}
                id="standard-number"
                label="Liczba pasażerów"
                value={this.state.arrivePassengers}
                onChange={this.changeArrivePassengers}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
            )}
            {this.state.error && (
              <p style={{ color: "red", margin: "0" }}>
                {this.state.error}
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Anuluj
          </Button>
            <Button onClick={this.startRouteAction} color="primary">
              Rozpocznij
          </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isOpenEnd} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Czy na pewno zakończyć trasę?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Anuluj
          </Button>
            <Button onClick={this.endRouteAction} color="primary">
              Zakończ trasę
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.app.routes,
    routeData: state.app.routeData,
    routeDataLoading: state.app.routeDataLoading
  };
};

export default withRouter(
  connect(mapStateToProps, { startRoute, endRoute })(withStyles(styles)(RouteAction))
);
