import React from 'react';
import './App.scss';
import 'react-notifications-component/dist/theme.css'

import LoginPage from './components/login/LoginPage';
import RoutesPage from './components/routes/RoutesPage';
import RoutePage from './components/route/RoutePage';
import EditReservationContainer from './components/reservation/EditReservationContainer';
import SettlementPage from './components/settlement/SettlementPage';
import CreateReservation from './components/reservation/CreateReservation';
import Attendance from './components/attendance/AttendancePage';
import DriversPage from './components/drivers/DriversPage';
import Notifications from './components/common/Notifications';
import TrackingLocalization from './components/common/TrackingLocalization';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import reducer from './reducers/appReducer';
import ReduxThunk from 'redux-thunk' // no changes here
import { reducer as formReducer } from 'redux-form'
import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import orange from '@material-ui/core/colors/deepOrange';
import blue from '@material-ui/core/colors/blue';
import {
  PathMatcher, ROUTE_SHOW_PATH, HOMEPAGE_PATH, RESERVATION_NEW_PATH,
  ATTENDANCE_PATH, SETTLEMENT_PATH, SPLIT_RESERVATION_PATH, EDIT_RESERVATION_PATH
} from './PathMatcher';
import {version} from '../package.json';

const secondary = orange['600'];
const primary = blue['500'];

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    }
  },
});

//import DEV library only in DEV environment
if (process.env.NODE_ENV !== "production") {
  var reduxLogger = require('redux-logger');
}

class App extends React.Component {

  render() {
    var composeEnhancers = compose;
    var middleware = [];
    const history = createBrowserHistory();

    middleware.push(routerMiddleware(history));
    middleware.push(ReduxThunk);
    if (process.env.NODE_ENV !== "production") {
      middleware.push(reduxLogger.createLogger());
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
    }

    console.log("VERSION: ", version);

    const combinedReducers = (history) => combineReducers({
      router: connectRouter(history),
      app: reducer,
      form: formReducer,
    })

    const enhancer = composeEnhancers(
      applyMiddleware(...middleware)
    );

    const store = createStore(
      combinedReducers(history),
      {},
      enhancer
    );

    if (window.Cypress) {
      window.store = store
    }

    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Notifications />
            <TrackingLocalization />
            <ConnectedRouter history={history}>
              <Switch>
                <Route path={PathMatcher.routerPath(HOMEPAGE_PATH)} exact component={RoutesPage} />
                <Route path="/login" exact component={LoginPage} />
                <Route path={PathMatcher.routerPath(ROUTE_SHOW_PATH)} exact component={RoutePage} />
                <Route path={PathMatcher.routerPath(RESERVATION_NEW_PATH)} exact component={CreateReservation} />
                <Route path="/routes/:id/reservation/:reservationId/edit" exact component={CreateReservation} />
                <Route path={PathMatcher.routerPath(ATTENDANCE_PATH)} exact component={Attendance} />
                <Route path={PathMatcher.routerPath(SETTLEMENT_PATH)} exact component={SettlementPage} />
                <Route path={PathMatcher.routerPath(SPLIT_RESERVATION_PATH)} exact component={EditReservationContainer} />
                <Route path={PathMatcher.routerPath(EDIT_RESERVATION_PATH)} exact component={EditReservationContainer} />
                <Route path="/drivers-list" exact component={DriversPage} />
              </Switch>
            </ConnectedRouter>
          </div>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
