import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import {
  setReservationAsNoShow, setReservationAsGratis,
  setReservationCardPayment, setReservationPrice, removeNoShowAndGratis,
  createErrorNotification
} from 'reducers/appActions';
import { PathMatcher } from 'PathMatcher';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = {
  yellow: {
    backgroundColor: "#ffc310",
  },
  blue: {
    backgroundColor: "#2195f3",
  },
  green: {
    backgroundColor: "#11ac45",
  },
  phoneLink: {
    textDecoration: "none",
    color: "inherit"
  }
};

class ReservationItemActions extends React.Component {

  constructor(props) {
    super(props);
    var value = "";
    if (props.noShow) {
      value = "noshow";
    } else if (props.isGratis) {
      value = "gratis";
    }
    this.state = {
      isOpen: false,
      newPrice: '',
      priceError: null,
      radioValue: value,
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  changeCardPayment = (e) => {
    // const { reservation, setReservationCardPayment } = this.props;
    // setReservationCardPayment({
    //   reservationId: reservation.id,
    //   value: e.target.checked
    // });
  }

  changePriceAction = (e) => {
    // e.preventDefault();
    // this.setState({ priceError: null });
    // const { reservation, setReservationPrice } = this.props;
    // var newPrice = this.state.newPrice;
    // if (newPrice < 1 || !Number.isInteger(newPrice)) {
    //   this.setState({ priceError: "Cena jest niepoprawna" });
    // } else {
    //   setReservationPrice({
    //     reservationId: reservation.id,
    //     value: newPrice
    //   });
    //   this.setState({ newPrice: '' });
    // }
  }

  handleChangePrice = (e) => {
    this.setState({ newPrice: parseInt(e.target.value) });
  }

  handleChangeRadio = (e) => {
    // var value = e.target.value;
    // const { reservation, setReservationAsGratis, removeNoShowAndGratis,
    //   setReservationAsNoShow } = this.props;
    // this.setState({ radioValue: value });
    // if (value === "noshow") {
    //   setReservationAsNoShow({
    //     reservationId: reservation.id,
    //   });
    // }
    // if (value === "gratis") {
    //   setReservationAsGratis({
    //     reservationId: reservation.id,
    //   });
    // }
    // if (value === "") {
    //   removeNoShowAndGratis({
    //     reservationId: reservation.id,
    //   })
    // }
  }

  render() {
    const { reservation, cardPayment,
      sendingRequestReservation } = this.props;
    const { isOpen } = this.state;
    return (
      <div>
        <Button
          className="center-block"
          onClick={this.handleOpen}
          size="small"
          variant="contained"
        >
          Akcje
        </Button>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={isOpen}
          className="dialog-reservation-actions"
        >
          {sendingRequestReservation
            ?
            <div style={{ margin: "22px" }}>
              <p>Zapisuję zmiany...</p>
            </div>
            :
            <div style={{ margin: "22px" }}>
              <RadioGroup
                aria-label="position"
                name="status"
                value={this.state.radioValue}
                onChange={this.handleChangeRadio}
                row
              >
                <FormControlLabel
                  value=""
                  control={<Radio color="primary" />}
                  label="Brak"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="noshow"
                  control={<Radio color="primary" />}
                  label="No show"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="gratis"
                  control={<Radio color="primary" />}
                  label="Gratis"
                  labelPlacement="start"
                />
              </RadioGroup>
              <FormControl error={false}>
                <FormControlLabel
                  control={
                    <Checkbox
                      label="Zapłacono kartą"
                      checked={cardPayment}
                      onChange={this.changeCardPayment}
                    />
                  }
                  label="Zapłacono kartą"
                />
              </FormControl>
              <div>
                <TextField
                  style={{ display: "inline-block" }}
                  error={false}
                  id="standard-number"
                  label="Nowa cena rezerwacji"
                  value={this.state.newPrice}
                  onChange={this.handleChangePrice}
                  helperText={"Obecna cena: " + reservation.price + " NOK"}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
                <Button
                  style={{ display: "inline-block", top: "30px" }}
                  onClick={this.changePriceAction}
                  size="small"
                  variant="contained"
                >
                  Zmień cenę
                </Button>
                {this.state.priceError && (
                  <p style={{ margin: "0px", color: "red" }}>
                    {this.state.priceError}
                  </p>
                )}
              </div>
            </div>
          }
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    busStops: state.app.busStops,
    sendingRequestReservation: state.app.sendingRequestReservation
  };
};

const mapDispatchToProps = {
  // setReservationAsNoShow,
  // setReservationAsGratis,
  // setReservationCardPayment,
  // setReservationPrice,
  // removeNoShowAndGratis,
  // createErrorNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReservationItemActions))
);
